import React, {useRef,useEffect,useState} from 'react';
import {HeroContainer11, HeroBg,ImgBg,Img,ImgWrap,Timer, TimerText,TimerWrapper1, TimerWrapper,StickyBarWrraper, StickyBar,StickyBarText, VideoBg, HeroContent, HeroH11, HeroH2, HeroP, HeroBtnWrapper, InfoRow, Column11, Column2,HeroH22 } from '../HeroSection/HeroSectionElements'
import {NavBtnLink12,NavBtn} from '../Navbar/NavbarElemnts'
import {TopLine,TextwMatfokch,TextwMatfokch1,ServicesWrapper1,ServicesWrapper111,NavBtnLink11,NavBtnLink11AmaBerassmiLien, NavBtnLink13} from '../Forms/Topicele'
const HeaderAR = () => {

    const [timerDays, setTimerDays] = useState('00');
    const [timerHours, setTimerHours] = useState('00');
    const [timerMinutes, setTimerMinutes] = useState('00');
    const [timerSeconds, setTimerSeconds] = useState('00');
    const [timerDays1, setTimerDays1] = useState('00');
    const [timerHours1, setTimerHours1] = useState('00');
    const [timerMinutes1, setTimerMinutes1] = useState('00');
    const [timerSeconds1, setTimerSeconds1] = useState('00');
    let interval= useRef();

    const startTimer =() => {
        const countdownDate= new Date('May 16, 2021 00:00:00');

        interval=setInterval(() => {
            const now = new Date().getTime();
            const distance = countdownDate- now;
            const days= Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours= Math.floor((distance % (1000 * 60 * 60 * 24) / (1000 * 60 * 60)));
            const minutes= Math.floor((distance % (1000 * 60 * 60)) / (1000*  60));
            const seconds= Math.floor((distance % (1000 * 60)) / 1000);
            if (distance < 0){
                // stop our Timer
                clearInterval(interval.current);
            } else{
                setTimerDays(days);
                setTimerHours(hours);
                setTimerMinutes(minutes);
                setTimerSeconds(seconds);
                
            };
            
        }, 1000);
    };
    useEffect(() => {
        startTimer();
        return () => {
            clearInterval(interval.current);
        };
    });

    return (
        <>
        <HeroContainer11 id="Hero">
            <HeroBg>
                <ImgBg src={require('../../images/banner-22-AR.png').default} alt={"Common Action Boot Camp Med Dialogue for rights and equality European Union Tunisie"} />
            </HeroBg>
            <StickyBarWrraper>
            <StickyBar>
                <StickyBarText to='/CommonAction'>EN</StickyBarText>
                <StickyBarText to='/FR/CommonAction'>FR</StickyBarText>
                <StickyBarText to='/AR/CommonAction'>عر</StickyBarText>
            </StickyBar>
            </StickyBarWrraper>
            <HeroContent>
                <InfoRow>
                <br></br>
                    <br></br>
                   
                    
                </InfoRow>
                
            </HeroContent>
            
        </HeroContainer11>
               
        <ServicesWrapper1>
                <NavBtnLink11 to="/AR/Form">
                    استمارة التقديم 
                </NavBtnLink11>
                <NavBtnLink11 to="/AR/Guidelines" target="_blank" rel="noopener noreferrer">
                    إقرأ دعوة تقديم الترشحات
                </NavBtnLink11>
                <NavBtnLink11AmaBerassmiLien href="https://meddialogue.eu/fellowship-post/common-action" target="_blank">
                    الأعمال المشتركة السابقة       
                </NavBtnLink11AmaBerassmiLien>
        </ServicesWrapper1>
        </>
    )
}

export default HeaderAR
