import React from 'react'
import {TextField} from '@material-ui/core'
import {useField} from "formik";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';



const TextfieldWrapper4 = ({
  label,
  name,
  ...otherProps
}) => {
  const [field, mata] = useField(name);
 const theme = createMuiTheme({
  direction: 'rtl', 
  textAlign:"right"
});
  const configTextfield4 = {
    ...field,
    ...otherProps,
    fullWidth: true,
    variant: 'outlined'
  };

  if (mata && mata.touched && mata.error) {
    configTextfield4.error = true;
    configTextfield4.helperText = mata.error;
  }
  return (
    <ThemeProvider theme={theme}>
    <TextField placeholder={label} {...configTextfield4} />
    </ThemeProvider>
  );
};
function textfieldWrapper4PropsAreEqual(prevTextfieldWrapper4, nextTextfieldWrapper4) {
  return !(prevTextfieldWrapper4.value === nextTextfieldWrapper4.value);
  
}
export default  React.memo(TextfieldWrapper4, textfieldWrapper4PropsAreEqual);