import React from 'react';
import {Nav, NavbarContainer, Text, ServicesIcon,Sty,Textlink,NavBtnLink1,Container } from './NavbarElemnts'
import Dropdown from 'react-bootstrap/Dropdown'

const Navbar2 = () => {
    const digStyle = {   
        background: '#fff',
        textDecoration: 'None',
        }
    const dighStyle = {   
            
            outline: 'None',
        }           
    return (       
        <>
            <Nav >
                <NavbarContainer>
                    <Dropdown>
                        <Dropdown.Toggle style={dighStyle}>
                            <Sty>About <ServicesIcon src={require('../../images/arrow.png').default} /></Sty>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Container>
                                <Dropdown.Item href="https://meddialogue.eu/about-us/" style={digStyle}><Text>About Med Dialogue</Text></Dropdown.Item>
                                <Dropdown.Item href="https://meddialogue.eu/our-programme/" style={digStyle}><Text>Our Program</Text></Dropdown.Item>
                                <Dropdown.Item href="https://meddialogue.eu/eu-southern-neighbourhood/" style={digStyle}><Text>EU & Southern Neighbourhood</Text></Dropdown.Item>
                                <Dropdown.Item href="https://meddialogue.eu/about-us/" style={digStyle}><Text>Team</Text></Dropdown.Item>
                                <Dropdown.Item href="https://meddialogue.eu/our-consortium/" style={digStyle}><Text>Our Consortium</Text></Dropdown.Item>
                                <Dropdown.Item href="https://meddialogue.eu/mediterranean-tiles/" style={digStyle}><Text>Mediterranean tiles</Text></Dropdown.Item>
                            </Container>
                        </Dropdown.Menu>
                    </Dropdown>

                    
                    <Dropdown>
                        <Dropdown.Toggle style={dighStyle}>
                            <Sty>Vision Med 2030 <ServicesIcon src={require('../../images/arrow.png').default}/></Sty>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Container>
                                <Dropdown.Item href="https://meddialogue.eu/vision-med-2030/" style={digStyle}><Text>About Vision Med 2030</Text></Dropdown.Item>
                                <Dropdown.Item href="https://meddialogue.eu/vision-med/maghreb/" style={digStyle}><Text>Maghreb Workshop</Text></Dropdown.Item>
                                <Dropdown.Item href="https://meddialogue.eu/vision-med/mashreq/" style={digStyle}><Text>Mashreq Workshop</Text></Dropdown.Item>
                                <Dropdown.Item href="https://meddialogue.eu/vision-med/north-south/" style={digStyle}><Text>North-South Workshop</Text></Dropdown.Item>
                            </Container>
                        </Dropdown.Menu>
                    </Dropdown>

                    <Dropdown>
                        <Dropdown.Toggle style={dighStyle}>
                            <Sty>Capacity Development <ServicesIcon src={require('../../images/arrow.png').default}/></Sty>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Container>
                                <Dropdown.Item href="https://meddialogue.eu/capacity-development-page/" style={digStyle}><Text>About Capacity Development</Text></Dropdown.Item>
                                <Dropdown.Item href="https://meddialogue.eu/capacity-development/policy-dialogue/" style={digStyle}><Text>Policy Dialogue Program</Text></Dropdown.Item>
                                <Dropdown.Item href="https://meddialogue.eu/capacity-development/digital-space-as-civic-space/" style={digStyle}><Text>Digital Space as Civic Space Programme</Text></Dropdown.Item>
                            </Container>
                        </Dropdown.Menu>
                    </Dropdown>

                    <Dropdown>
                        <Dropdown.Toggle style={dighStyle}>
                            <Sty>Med Dialogue Leaders <ServicesIcon src={require('../../images/arrow.png').default}/></Sty>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Container>
                                <Dropdown.Item href="https://meddialogue.eu/med-dialogue-leaders/" style={digStyle}><Text>Med Dialogue Leaders</Text></Dropdown.Item>
                                <Dropdown.Item href="https://meddialogue.eu/thematic-bootcamps/" style={digStyle}><Text>Thematic Bootcamps </Text></Dropdown.Item>
                                <Dropdown.Item href="https://meddialogue.eu/fellowship-post/common-action/" style={digStyle}><Text>Common Actions</Text></Dropdown.Item>
                            </Container>
                        </Dropdown.Menu>
                    </Dropdown>

                    <Textlink href="https://meddialogue.eu/advocacy-campaigns/">
                        Advocacy Campaigns
                    </Textlink>
                    <Textlink href="https://meddialogue.eu/media/">
                        Media
                    </Textlink>
                    <Textlink href="https://meddialogue.eu/gallery/">
                        Gallery
                    </Textlink>
                    <NavBtnLink1 href="https://meddialogue.eu/contact-us/">Contact Us</NavBtnLink1>
                </NavbarContainer>
            </Nav>
            
        </>
    )
}

export default Navbar2;