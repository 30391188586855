import React from 'react'
import { InfoContainer, InfoWrapper, FormTitle } from './PersonalElements'
import {Formik, Form} from "formik";
import * as Yup from "yup";
import { Grid,Typography,Button } from '@material-ui/core';
import TextField from './FormsUI/Textfield/Textfield';
import TextField2 from './FormsUI/Textfield2/Textfield2';
import TextField3 from './FormsUI/Textfield3/Textfield3';
import TextField4 from './FormsUI/Textfield4/Textfield4';
import Other from './FormsUI/Other/Other';
import Select from './FormsUI/Select/Select';
import CurrentActivity from '../../Data/CurrentActivity.json';
import Experience from '../../Data/Experience.json';
import ExperienceToo from '../../Data/ExperienceField.json';
import Gender from '../../Data/Gender.json';
import Title from '../../Data/Title.json';
import YesNo from '../../Data/YesNo.json';
import languages from '../../Data/languages.json';
import DateTimePicker from './FormsUI/DataTimePicker/DataTimePicker';
import Radio from './FormsUI/Radio/Radio';
import Radio2 from './FormsUI/Radio2/Radio2';
import Radio3 from './FormsUI/Radio2/Radio3';
import Agree from './FormsUI/Agree/Agree';
import Buttonn from './FormsUI/Buttonn/Buttonn';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { getDatabase, ref as refReal, set } from "firebase/database";
import {AkwaLien} from "../Forms/Topicele";
import { v4 as uuidv4 } from 'uuid';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign:'center',
    border: '2px solid #01bf71',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));
const Hstyle = {
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily:"Segoe UI",
    fontWeight:'600',
    padding:'14px 8px',
  }
const styleJdid = {
    alignItems: 'center',
    justifyContent: 'center',
    textAlign:"justify",
    fontFamily:"Segoe UI",
    padding:'14px 8px',
  }
const Hhstyle = {
    fontFamily:"Segoe UI",
    fontWeight:'600',
    padding:'14px 0px',
  }
const Bstyle = {
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight:'600',
    padding:'16px 8px',
  }
const Sstyle = {
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight:'600',
    fontSize:"14px",
    color:"#01bf71",
    padding:'16px 8px',
  }
  
const INITIAL_FORM_STATE ={
    title:'',
    firstName:'',
    lastName:'',
    email:'',
    phone:'',
    aptNo:'',
    street:'',
    district:'',
    city:'',
    postalCode:'',
    country:'',
    gender:'',
    birthday:'',
    nationality:'',
    englishReading:'',
    englishSpeaking:'',
    englishListening:'',
    arabicReading:'',
    arabicSpeaking:'',
    arabicListening:'',
    frenchReading:'',
    frenchSpeaking:'',
    frenchListening:'',

    organisation:'',
    organisationLocation:'',
    // organisationActivities:'',
    currentWork:'',
    currentWork1:'',
    jobtitle:'',
    founder:'',
    awarness:'',
    experience:'',
    experienceTools:'',
    projectDescription:'',
    previousPartCritical:'',
    previousPartCritical1:'',
    previousPartAdvocacy:'',
    previousPartAdvocacy1:'',

    firstKey:'',
    secondKey:'',
    reason:'',
    
    CommonActionTitle:'',
    CommonActionPurpose:'',
    ProposedActivities:'',
    TargetGroup:'',
    timetable:'',
    specialCoaching:'',
    areasspecialCoaching:'',

    curriculumVitae:'',
    relfile:'',
    checkMe:''
};
const FORM_VALIDATION= Yup.object().shape({
    firstName: Yup.string()
        .required('Required'),
    lastName: Yup.string()
        .required('Required'),
    email: Yup.string()
        .email('Please Enter a Valid Email')
        .required('Required'),
    phone: Yup.number()
        .integer()
        .typeError('Please Enter a Valid Phone Number')
        .required('Required'),
    aptNo: Yup.string()
        .required('Required'),
    street: Yup.string()
        .required('Required'),
    district: Yup.string()
        .required('Required'),
    city: Yup.string()
        .required('Required'),
    postalCode: Yup.number()
        .integer()
        .typeError('Please Enter a Valid Postal Code')
        .required('Required'),
    country: Yup.string().required('Required'),
    birthday: Yup.date().required('Required'),
    nationality: Yup.string().required('Required'),
    englishReading: Yup.string().required('Required'),
    englishSpeaking: Yup.string().required('Required'),
    englishListening: Yup.string().required('Required'),
    arabicReading: Yup.string().required('Required'),
    arabicSpeaking: Yup.string().required('Required'),
    arabicListening: Yup.string().required('Required'),
    frenchReading: Yup.string().required('Required'),
    frenchSpeaking: Yup.string().required('Required'),
    frenchListening: Yup.string().required('Required'),
    
    organisation: Yup.string()
        .max(1500,'Please Do not Exceed 1500 Characters'),
    organisationLocation: Yup.string(),
    title: Yup
        .string()
        .required('Required'),
    gender: Yup
        .string()
        .required('Required'),
    currentWork : Yup
        .string(),
    currentWork1 : Yup
            .string(),
    jobtitle: Yup.string(),
    checkMe: Yup
        .boolean()
        .oneOf([true], "You need to Accept in Order to Validate")
        .required('Required'),
    founder: Yup.string(),
    awarness: Yup.string(),
    experience: Yup.string().required('Required'),
    experienceTools:Yup.string().required('Required'),
    projectDescription:Yup.string().required('Required'),
    previousPartCritical:Yup.string().required('Required'),
    previousPartCritical1:Yup.string(),
    previousPartAdvocacy:Yup.string().required('Required'),
    previousPartAdvocacy1:Yup.string(),

    firstKey:Yup.string().required('Required')
                .max(1000,'Please Do not Exceed 1000 Characters'),
    secondKey:Yup.string().required('Required')
                .max(1000,'Please Do not Exceed 1000 Characters'),
    reason:Yup.string().required('Required')
                .max(1000,'Please Do not Exceed 1000 Characters'),
    
    CommonActionTitle: Yup.string().required('Required'),
    CommonActionPurpose: Yup.string().required('Required')
                            .max(750,'Please Do not Exceed 150 word'),
    ProposedActivities: Yup.string().required('Required')
                            .max(1250,'Please Do not Exceed 250 word'),
    TargetGroup:Yup.string().required('Required')
                            .max(750,'Please Do not Exceed 150 word'),
    timetable:Yup.string().required('Required'),
    specialCoaching:Yup.string().required('Required'),
    areasspecialCoaching:Yup.string(),

    resume: Yup
        .mixed()
        .required("A file is required")
})


// -------------------------Men hne yabdaa ba7th ------------------------------------------------------------------
const PersonalForm = () => {
    const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [filename, setFilename] = React.useState("CV");
  const [filename1, setFilename1] = React.useState("Relevant File");
  const handleOpen = () => {
    setOpen(true);
  };
  function refreshPage() {
    window.location.reload(false);
  }
  const handleClose = () => {
    setOpen(false);
    refreshPage();
  };
    return (
        <>
            <InfoContainer>
                <InfoWrapper>
                    <Formik initialValues={{
                        ...INITIAL_FORM_STATE
                        }}
                        validationSchema={FORM_VALIDATION}
                        onSubmit={values =>{

                            console.log(values);

                            const storage = getStorage();
                            const db = getDatabase();

                            const file= values.resume;
                            const relevantFile= values.relevantFile;
                            const cvname= (values.firstName + ' ' +  values.resume.name);
                            let rvname='';
                            if (filename1!=='Relevant File'){
                                rvname= (values.firstName + ' ' +  values.relevantFile.name );
                            }
                            const storageRefCV = ref(storage, 'CV/'+cvname);
                            // const CV = storageRefCV.child();
                            if (rvname!==''){
                            const storageRefRF = ref(storage, 'RF/'+rvname);
                            uploadBytes(storageRefRF, relevantFile).then((snapshot) => {
                                console.log('Uploaded a Rf!');
                              });
                            }
                            uploadBytes(storageRefCV, file).then((snapshot) => {
                                console.log('Uploaded a RESUME!');
                              });
                            values.curriculumVitae=cvname;
                            values.relfile=rvname;
                            // let messageRef = firebase.database().ref('Applicants').orderByKey().limitToLast
                            set(refReal(db, 'users/'+values.firstName +" "+values.lastName + " "+uuidv4()), {
                                values
                              });
                            handleOpen();
                        }}
                    >
                    {({ 
                        setFieldValue,
                        setFieldTouched,
                        values,
                        errors,
                        touched,
                    }) => (
                            
                        <Form>
                        <FormTitle>1. Personal Information </FormTitle>
                            <Grid container spacing={2}>
                            
                                <Grid item md={3} sm={12}>
                                    <Radio
                                        name="title"
                                        label="Title"
                                        legend="Title"
                                        options={Title}
                                
                                    />
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <TextField
                                      name="firstName"
                                      label="First Name"
                                      
                                    />
                                </Grid>

                                <Grid item md={4} sm={12}>
                                    <TextField
                                      name="lastName"
                                      label="Last Name"
                                    />
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <Radio
                                        name="gender"
                                        legend="Gender"
                                        label="Gender"
                                        options={Gender}
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                      name="email"
                                      label="Email"
                                    />
                                </Grid>

                                <Grid item md={4} sm={12}>
                                    <TextField
                                      name="phone"
                                      label="Phone"
                                    />
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <TextField
                                        name="nationality"
                                        label="Nationality"
                                    />
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <DateTimePicker
                                      name="birthday"
                                      label="Date of Birth"
                                      
                                    />
                                </Grid>
                                
                                 <Grid item xs={12}>
                                    <Typography style={Hhstyle}>
                                        Residential Address
                                    </Typography>
                                </Grid>

                                <Grid item md={4} sm={12}>
                                    <TextField
                                      name="aptNo"
                                      label="Apartment Number"
                                    />
                                </Grid>
                                
                                <Grid item md={4} sm={12}>
                                    <TextField
                                      name="street"
                                      label="Street"
                                    />
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <TextField
                                      name="district"
                                      label="District"
                                    />
                                </Grid>

                                <Grid item md={4} sm={12}>
                                    <TextField
                                      name="city"
                                      label="City / Town"
                                    />
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <TextField
                                      name="postalCode"
                                      label="Postal Code"
                                    />
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <TextField
                                        name="country"
                                        label="Country"
                                    />
                                </Grid>
                                
                               
{/*********** Language Skills *************/}
                                <Grid item xs={12}>
                                    
                                <FormTitle><br></br><br></br>2. Languages Skills  </FormTitle>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography style={Hhstyle}>
                                        English
                                    </Typography>
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <Select
                                        name="englishReading"
                                        label="Reading"
                                        options={languages}
                                    />
                                </Grid>

                                <Grid item md={4} sm={12}>
                                    <Select
                                        name="englishSpeaking"
                                        label="Speaking"
                                        options={languages}
                                    />
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <Select
                                        name="englishListening"
                                        label="Listening"
                                        options={languages}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography style={Hhstyle}>
                                        French
                                    </Typography>
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <Select
                                        name="frenchReading"
                                        label="Reading"
                                        options={languages}
                                    />
                                </Grid>

                                <Grid item md={4} sm={12}>
                                    <Select
                                        name="frenchSpeaking"
                                        label="Speaking"
                                        options={languages}
                                    />
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <Select
                                        name="frenchListening"
                                        label="Listening"
                                        options={languages}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography style={Hhstyle}>
                                        Arabic
                                    </Typography>
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <Select
                                        name="arabicReading"
                                        label="Reading"
                                        options={languages}
                                    />
                                </Grid>

                                <Grid item md={4} sm={12}>
                                    <Select
                                        name="arabicSpeaking"
                                        label="Speaking"
                                        options={languages}
                                    />
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <Select
                                        name="arabicListening"
                                        label="Listening"
                                        options={languages}
                                    />
                                </Grid>
                                
{/************* Relevent experience **************/}
                                <FormTitle><br></br><br></br>3. Experience  </FormTitle>
                                        <Grid  style={Hstyle} xs={12}>
                                        Your organisation name (if any):
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField2 
                                            name="organisation"
                                            />
                                        </Grid><br></br>
                                        <Grid  style={Hstyle} xs={12}>
                                            Your organisation location/ country (if any):
                                        </Grid>
                                        <Grid item sm={12}>
                                            <TextField2 
                                            name="organisationLocation"
                                            />
                                        </Grid>
                                        <Grid  style={Hstyle} xs={12}>
                                            Your position/Job title:
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField2
                                            name="jobtitle"
                                            />
                                        </Grid>
                                        <br></br>
                                        <Grid item xs={12}>
                                            <Radio 
                                            name="founder"
                                            legend="Are you  a founder of or a key person in the organisation?"
                                            options={YesNo}
                                            />
                                        </Grid>
                                <Grid item xs={12}>
                                    <Radio2 
                                        legend="Your position / service in the organisation is:"
                                        name="currentWork"
                                        options={CurrentActivity}
                                    /><br></br>
                                </Grid>
                                {values.currentWork==="Other" ? <>
                                        <Grid  style={Hstyle} xs={12}>
                                            Please Specify:
                                        </Grid>
                                        <Grid item sm={8}>
                                            <TextField2 
                                            name="currentWork1"
                                            />
                                        </Grid></>
                                        :
                                        ""
                                    }
                                <Grid item xs={12}>
                                    <Radio2
                                        legend="Are you applying to this Call in coordination with the board or management of your organisation?" 
                                        name="awarness"
                                        options={YesNo}
                                    />
                                </Grid>
                                <Grid xs={12} >
                                    <Grid item xs={12}>
                                        <Radio3
                                            legend="For how many years have you been involved in CSOs taking a leading role in your community or volunteering in one form or another? For the definition of CSO, check " 
                                            lien="Call’s Guidelines"
                                            winMachi="/Guidelines"
                                            name="experience"
                                            options={Experience}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Radio2
                                            legend="For how long have you been engaged in Developing critical thinking tools in your work / activity?" 
                                            name="experienceTools"
                                            options={ExperienceToo}
                                        />
                                    </Grid>
                                    <Grid  style={Hstyle} xs={12}>
                                    Please describe a project aimed at developing critical thinking notions and tools in which you were involved as a leader or a participant:
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField2 
                                        name="projectDescription"
                                        multiline={true}
                                        rows={4}
                                        />
                                    </Grid><br></br>
                                        <Grid item xs={12}>
                                            <Radio 
                                            name="previousPartCritical"
                                            legend="Have you previously participated in training sessions on critical thinking tools?"
                                            options={YesNo}
                                            />
                                        </Grid>
                                        {values.previousPartCritical==="Yes"? 
                                            <>
                                            <Grid  style={Hstyle} xs={12}>
                                            How many and for how long?
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField2 
                                                name="previousPartCritical1"
                                                multiline={true}
                                                rows={2}
                                                />
                                            </Grid>
                                            </>:""
                                        }<br></br>
                                        <Grid item xs={12}>
                                            <Radio 
                                            name="previousPartAdvocacy"
                                            legend="Have you participated in advocacy / awareness-raising workshops or in drafting an advocacy campaign based on the use of critical thinking notions and tools? "
                                            options={YesNo}
                                            />
                                        </Grid>
                                        {values.previousPartAdvocacy==="Yes"? 
                                            <>
                                            <Grid  style={Hstyle} xs={12}>
                                            Please Describe
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField2 
                                                name="previousPartAdvocacy1"
                                                multiline={true}
                                                rows={2}
                                                />
                                            </Grid>
                                            </>:""
                                        }
                                <br></br><br></br>
                                </Grid>
                                

            {/************* 4.	Motivation  **************/}
            <FormTitle>4.	Motivation </FormTitle>
                                        <br></br>
                                <Grid xs={12} style={{marginLeft:10}}>
                                    <Typography variant="caption"> In this section, you can use up to 1000 characters per question, spaces included</Typography>
                                </Grid>
                                <Grid  style={Hstyle} xs={12}>
                                Describe two personal key objectives  that you want to achieve through your participation in Med Dialogue Leaders Bootcamp:
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField4 
                                      name="firstKey"
                                      multiline={true}
                                      rows={3}
                                      label="First Key Objective"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField2 
                                      name="secondKey"
                                      multiline={true}
                                      rows={3}
                                      label="Second Key Objective"
                                    />
                                </Grid>
                                <Grid  style={Hstyle} xs={12}>
                                    Briefly describe how and why your participation in Med Dialogue Leaders Bootcamp would be beneficial to you and your future commitment  to your community / organisation:
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField2 
                                      name="reason"
                                      multiline={true}
                                      rows={3}
                                      label=""
                                    />
                                </Grid>
    {/************* 5.	ICT tool Description  **************/}
                                <FormTitle><br></br><br></br>5. Your common action proposal   </FormTitle>
                                <Grid  style={styleJdid} xs={12}>
                                    <b>During the Bootcamp</b>, the young leaders will be working at the conception of a common action, which shall take place simultaneously in the young leaders’ hometowns/regions as a cluster of local activities meeting shared and coherent criteria.
                                    <br></br>
                                    The applicant is therefore hereby invited to submit a proposal for such a common action, clearly presenting the<b> envisioned objective of the common action and the set of activities needed to achieve the objective and their impact at the regional scale. </b>
                                    <br></br>
                                    <br></br>
                                    Before starting to fill in the Common Action form below, please read carefully <AkwaLien to="/commonAction" target="_blank" rel="noopener noreferrer">"What's a Common Action?"</AkwaLien> section.
                                </Grid>
                                <Grid  style={Hstyle} xs={12}>
                                    <b>Your Common action title :</b> <br></br>
                                    Formulate a title that reflects the key project idea of your proposal for a common action.
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField3 
                                      name="CommonActionTitle"
                                    />
                                </Grid>
                                <Grid  style={Hstyle} xs={12}>
                                    <b>Your Common action purpose and expected results (150 words max) :</b> <br></br>
                                    Formulate action purpose and foreseen results. Keep in mind that the action must be regional and aiming at addressing main critical thinking challenges in the Mediterranean, as well as at improving the life quality of the applicant’s community. The proposed common action can take inspiration from an assessment of the needs and constraints of the applicant’s community/context, and aims at proposing solutions to address critical thinking challenges in other areas of the whole region.
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField3 
                                      name="CommonActionPurpose"
                                      multiline={true}
                                      rows={2}
                                    />
                                </Grid>
                                <Grid  style={Hstyle} xs={12}>
                                    <b>Your proposed activities (250 words max) :</b> <br></br>
                                    <ul style={{marginLeft:30}}>
                                        <li>Describe the set of activities to be undertaken to produce results and specify your possible contribution in that.</li>
                                        <li>Explain how the proposed activities ensure the expected results.</li>
                                        <li>Explain whether the proposed activities have a replication potential (multiplier effects), and whether it could produce sustainable effects after its completion.</li>
                                    </ul>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField3 
                                      name="ProposedActivities"
                                      multiline={true}
                                      rows={3}
                                    />
                                </Grid>
                                <Grid  style={Hstyle} xs={12}>
                                    <b>Your Target Group / Partners (150 words max) :</b> <br></br>
                                    Describe who would be the target groups of the proposed common action, and the potential partner entities with whom it could be carried out
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField3 
                                      name="TargetGroup"
                                      multiline={true}
                                      rows={2}
                                    />
                                </Grid>
                                <Grid  style={Hstyle} xs={12}>
                                    <b>Your Indicative timetable :</b> <br></br>
                                    Suggest an indicative length of the proposed common action (one day, one week, etc.), keeping in mind that it should take place within a restricted time frame, so as to embolden its critical mass.
                                    It is recommended to base the estimated duration of the common action by taking into consideration all relevant factors that may affect the implementation timetable.
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField4 
                                      name="timetable"
                                      multiline={true}
                                      rows={4}
                                    />
                                </Grid>

{/************* 6.	Additional information    **************/}
                                <Grid xs={12}><br></br><br></br><br></br>
                                    <FormTitle>6.	Additional information  </FormTitle>
                                </Grid>
                                <Grid item xs={12}>
                                    <Radio2
                                        name="specialCoaching"
                                        legend="Would you require a mentorship for the implementation of your local action in the framework of the common action?"
                                        options={YesNo}
                                    />
                                </Grid>
                                {
                                    (values.specialCoaching==="Yes") ?
                                        (<Grid item xs={12}>
                                        <Grid style={Hstyle} xs={12}>
                                            If so, explain in which areas of expertise and why you think you’d need it.
                                        </Grid>
                                        <Grid xs={12}><Other
                                        name="areasspecialCoaching"
                                        
                                        /></Grid>
                                    </Grid>): ""
                                }
                                <Grid  style={Bstyle} xs={5}>
                                    {errors.resume ? 
                                    <Typography style={{color:"red"}}>Please provide your recent CV/ Resume*</Typography>
                                    :
                                    "Please provide your recent CV/ Resume*"}
                                </Grid>

                                <Grid item xs={2}>
                                    <Button
                                        variant="contained"
                                        component="label"
                                        fullwidth
                                        >
                                        Upload File
                                        <input
                                            name="resume"
                                            type="file"
                                            hidden
                                            onChange={(event) => (setFieldValue('resume', event.target.files[0]),setFilename(event.target.files[0].name) )}
                                        />
                                        </Button>
                                </Grid>
                                
                                        {/* {(filename=='CV') ?(
                                            <Grid md={4} sm={12} style={redHstyle} >{filename}</Grid>
                                        ):
                                        ( */}
                                            <Grid md={4} sm={12} style={Hstyle} >{filename}</Grid>
                                        {/* )
                                        } */}
                                <br></br>
                                <Grid xs={12} style={{marginLeft:15,color:"red",fontSize:12}}>
                                {errors.resume}
                                </Grid>
                                <Grid  style={Bstyle} xs={5}>
                                    Please provide a recommendation letter 
                                </Grid>

                                <Grid item xs={2}>
                                    <Button
                                        variant="contained"
                                        component="label"
                                        fullwidth
                                        >
                                        Upload File
                                        <input
                                            name="relevantFile"
                                            type="file"
                                            hidden
                                            onChange={(event) => (setFieldValue('relevantFile', event.target.files[0]),setFilename1(event.target.files[0].name))}
                                            
                                        />
                                        {/* {if (!values.resume)} */}
                                        </Button>
                                </Grid>
                                <Grid md={4} sm={12} style={Hstyle}>{filename1}</Grid>
                                <br></br>
{/************* 8.	Applicant declaration    **************/}
                                <Grid xs={12}><br></br><br></br><br></br>
                                    <FormTitle>7.	Applicant declaration </FormTitle>
                                </Grid>
                                <Grid  style={Hstyle} xs={12}>
                                The applicant, in the context of the present call Med Dialogue Leaders, hereby declares that:
                                </Grid>
                                <Grid  style={Sstyle} xs={12}>
                                 - She / he meets the eligibility criteria specified in the call.
                                </Grid>
                                <Grid  style={Sstyle} xs={12}>
                                 - She /he has the capacity to carry out the common action developed during the bootcamp in his/her community, or with his/her associated organisation(s), including the relevant professional competences and qualifications.
                                </Grid>
                                <Grid  style={Sstyle} xs={12}>
                                 - She /he would be directly responsible for the preparation, management and implementation of the common action in his/her community.
                                </Grid>
                                <Grid  style={Sstyle} xs={12}>
                                    - She /he would be in the position to start the common action implementation within the requested timeframe.
                                </Grid>
                                <Grid  style={Sstyle} xs={12}>
                                 - She/ he is available for participation in the Bootcamp activity in January 2022 and able to travel.
                                </Grid>
                                <Grid item xs={12}>
                                    <Agree
                                      name="checkMe"
                                      legend="By clicking here, you will sign the “Applicant declaration”"
                                      label="I agree"
                                    />
                                </Grid>
                                <Grid  style={Hstyle} xs={12}>
                                 <i>Before clicking “Submit” button please make sure you have checked all provided information and attached all necessary supporting documentation. Failure to submit the required information and documentation within the deadline specified may lead to rejection of the application. <br></br>You will not be able to revise the application form after submission. If you are fully confident that your application is complete and ready for submission click “Submit” button below.</i>
                                </Grid>
                                <Modal
                                        aria-labelledby="Thank you for your submission" className={classes.modal}
                                        open={open}
                                        onClose={handleClose}
                                        closeAfterTransition
                                        BackdropComponent={Backdrop}
                                        BackdropProps={{
                                        timeout: 500,
                                        }}
                                    >
                                        <Fade in={open}>
                                        <div className={classes.paper}>
                                            <h2 id="transition-modal-title">Thank you for your submission {values.firstName} {values.lastName}</h2>
                                            </div>
                                        </Fade>
                                    </Modal>

                                    

                                <Grid item xs={12}>
                                    <Buttonn >
                                        Submit
                                    </Buttonn>
                                </Grid>
                            </Grid>
                        </Form>)}
                    </Formik>
                </InfoWrapper>
            </InfoContainer>
        </>
    )
}

export default PersonalForm
