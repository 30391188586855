import './App.css';
import FormCivic from './Pages/FormCivic'
import Guideline from './Pages/GuidelineEN'
import frequentlyAskedQuestions from './Pages/frequentlyAskedQuestions'
import firebase from "./firebase"
import FormCivicAR from './Pages/Arabic/FormCivic'
import GuidelineAR from './Pages/GuidelineAR'
import frequentlyAskedQuestionsAR from './Pages/Arabic/frequentlyAskedQuestions'

import FormCivicFR from './Pages/French/FormCivic'
import GuidelineFR from './Pages/GuidelineFR'
import frequentlyAskedQuestionsFR from './Pages/French/frequentlyAskedQuestions'
import {BrowserRouter as Router, Switch, Route}from 'react-router-dom'

function App() {
  return (
    <Router>
        <Switch>
          <Route path="/Guidelines" component={Guideline} target="_blank"  exact/>
          <Route path="/CommonAction" component={frequentlyAskedQuestions} exact/>
          <Route path="/Form" component={FormCivic} exact/>
        
          <Route path="/AR/Form" component={FormCivicAR} exact/>
          <Route path="/AR/Guidelines" component={GuidelineAR} target="_blank" exact/>
          <Route path="/AR/CommonAction" component={frequentlyAskedQuestionsAR} exact/>
          
          <Route path="/FR/Form" component={FormCivicFR} exact/>
          <Route path="/FR/Guidelines" component={GuidelineFR} exact target="_blank"/>
          <Route path="/FR/CommonAction" component={frequentlyAskedQuestionsFR} exact/>
          <FormCivic/>
        </Switch>
    </Router>
  );
}

export default App;
