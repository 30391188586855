import React from 'react'
import {TextField} from '@material-ui/core'
import {useField} from "formik";



const TextfieldWrapper1 = ({
  name,
  ...otherProps
}) => {
  const [field, mata] = useField(name);

  const configTextfield = {
    ...field,
    ...otherProps,
    fullWidth: true,
    variant: 'outlined'
  };

  if (mata && mata.touched && mata.error) {
    configTextfield.error = true;
    configTextfield.helperText = mata.error;
  }
  return (
    <TextField {...configTextfield} 
               />
    
  );
};
function textfieldWrapper1PropsAreEqual(prevTextfieldWrapper1, nextTextfieldWrapper1) {
  return !(prevTextfieldWrapper1.value === nextTextfieldWrapper1.value);
  
}
export default  React.memo(TextfieldWrapper1, textfieldWrapper1PropsAreEqual);