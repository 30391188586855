import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Header from './headerFR';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: '30px 0 30px 0',
    
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 'bold',
    textAlign:'center',
    alignContent:'center',
    display:'block',
    alignItems:'center'
  },
  heading2: {
    fontSize: theme.typography.pxToRem(15),
    textAlign:'center',
    padding:'5px'
  },
  heading2: {
    fontSize: theme.typography.pxToRem(15),
    padding:'5px'
  },
  headingKbir: {
    fontSize: theme.typography.pxToRem(30),
    textAlign:'center',
    fontWeight:800,
    padding:'15px'
  },
  paper:{
    backgroundColor:'#338fbd',
    color:'#fff',
    textAlign:'center',
    alignContent:'center',
    alignItems:'center',
    "& .MuiAccordionSummary-content": { display: "block" }
  }
}));

const FaqFR = () => {
  const classes = useStyles();

  return (
    <>
        <Header/>
    <div className={classes.root}>
      <Typography className={classes.headingKbir}>
        Qu'est-ce qu'une Action Commune ?
      </Typography>
      <Typography className={classes.heading2}>
      Une action commune peut être définie comme un programme de plusieurs actions de sensibilisation (campagnes d'information / de plaidoyer, etc.) fondées sur les besoins et le contexte locaux de la région où elles se déroulent et mises en œuvre par une organisation ou un consortium d’organisations. 
      </Typography>
      <Typography className={classes.heading2}>
      Une action commune doit avoir un objectif commun, un cadre défini en commun et une coordination opérationnelle centrale ; elle répond à des critères cohérents définis collectivement et se réalise à travers des activités décentralisées dans un délai bien défini, de manière à renforcer son impact critique. Toutes ces caractéristiques seront discutées et convenues lors du Bootcamp.
      </Typography>
      <br></br>
      <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={classes.paper}
        >
          <Typography className={classes.heading}>Durée de l’action</Typography>
        </AccordionSummary>
        <AccordionDetails >
          <Typography style={{textAlign:"justify"}}>
          L’action commune sera mise en œuvre au mois de mars 2022 (à titre indicatif), dans un délai à déterminer lors du Bootcamp. 
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className={classes.paper}
        >
          <Typography className={classes.heading}>Ressources</Typography>
        </AccordionSummary>
        <AccordionDetails >
          <Typography style={{textAlign:"justify"}}>
          Pour mener à bien les activités de l'action commune, les organisations / réseaux de Leaders se verront confier une contribution limitée aux coûts opérationnels (1.700 € pour chaque activité décentralisée). Les objectifs, le format et le calendrier de l'action commune seront discutés et convenus au cours du Bootcamp.
          </Typography>
        </AccordionDetails>
      </Accordion>


      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className={classes.paper}
        >
          <Typography className={classes.heading}>Reporting</Typography>
        </AccordionSummary>
        <AccordionDetails >
          <Typography style={{textAlign:"justify"}}>
          L'équipe d'assistance technique guidera et suivra la mise en œuvre de chaque action locale. Les bénéficiaires seront tenus de soumettre un rapport financier et moral final après l’action mise en œuvre ; ce rapport incluera notamment un support audiovisuel documentant l’action. Un aperçu des exigences en matière de rapport sera fourni aux bénéficiaires dans le « Guide de gestion de l’action commune ». 
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className={classes.paper}
        >
          <Typography className={classes.heading}>Visibilité </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{textAlign:"justify"}}>
          Les bénéficiaires doivent assurer la visibilité du soutien de l'Union européenne, représenté dans le cas de figure par le programme d'assistance technique de Med Dialogue for Rights and Equality. Veuillez consulter le site :<a href="https://ec.europa.eu/international-partnerships/comm-visibility-requirements_en" target="_blank">Communication and Visibility Requirements for EU External Actions specified and published by the European Commission</a>            </Typography>
        </AccordionDetails>
      </Accordion>
      <br></br>
    </div>
    </>
  );
}

export default FaqFR
