import React, {useRef,useEffect,useState} from 'react';
import {HeroContainer1, HeroBg,ImgBg,Img,ImgWrap,Timer, TimerText,TimerWrapper1, TimerWrapper,StickyBarWrraper, StickyBar,StickyBarText, VideoBg, HeroContent, HeroH11, HeroH2, HeroP, HeroBtnWrapper, InfoRow, Column11, Column2,HeroH22 } from '../HeroSection/HeroSectionElements'
import {NavBtnLink1,NavBtnLink1197,NavBtn} from '../Navbar/NavbarElemnts'
import {TopLineAR,TextwMatfokch,TextwMatfokch1,ServicesWrapper1,NavBtnLink11, NavBtnLink11AmaBerassmiLien} from './Topicele'
const FormCivicAR= () => {
const [hover, setHover] = useState(false)

    const [timerDays, setTimerDays] = useState('00');
    const [timerHours, setTimerHours] = useState('00');
    const [timerMinutes, setTimerMinutes] = useState('00');
    const [timerSeconds, setTimerSeconds] = useState('00');
    const [timerDays1, setTimerDays1] = useState('00');
    const [timerHours1, setTimerHours1] = useState('00');
    const [timerMinutes1, setTimerMinutes1] = useState('00');
    const [timerSeconds1, setTimerSeconds1] = useState('00');
    let interval= useRef();

    const startTimer =() => {
        const countdownDate= new Date('Octobre 22, 2021 15:00:00');

        interval=setInterval(() => {
            const now = new Date().getTime();
            const distance = countdownDate- now;
            const days= Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours= Math.floor((distance % (1000 * 60 * 60 * 24) / (1000 * 60 * 60)));
            const minutes= Math.floor((distance % (1000 * 60 * 60)) / (1000*  60));
            const seconds= Math.floor((distance % (1000 * 60)) / 1000);
            if (distance < 0){
                // stop our Timer
                clearInterval(interval.current);
            } else{
                setTimerDays(days);
                setTimerHours(hours);
                setTimerMinutes(minutes);
                setTimerSeconds(seconds);
                
            };
            
        }, 1000);
    };
    useEffect(() => {
        startTimer();
        return () => {
            clearInterval(interval.current);
        };
    });
const onHover = () =>{
    setHover(!hover)
}

    return (
        <>
        
        <HeroContainer1 id="Hero">
            <HeroBg>
                <ImgBg src={require('../../images/banner-22-AR.png').default} alt={"Boot Camp Med Dialgoue for rights and equality funded by European Union"} />
            </HeroBg>
            <StickyBarWrraper>
            <StickyBar>
                <StickyBarText to='/Form'>EN</StickyBarText>
                <StickyBarText to='/FR/Form'>FR</StickyBarText>
                <StickyBarText to='/AR/Form'>عر</StickyBarText>
            </StickyBar>
            </StickyBarWrraper>
            <HeroContent>
                <InfoRow>
                    {/* <Column11>
                    <HeroH11 to='/'><b>#HackToAct</b> Hackathon</HeroH11>
                        <HeroH2>
                        <b>Civil society activists registration is Closed!</b>
                        </HeroH2>
                        
                    </Column11> */}
                    
                    
                    
                </InfoRow>
                
            </HeroContent>
            
        </HeroContainer1>
        <ServicesWrapper1>
                <NavBtnLink11 to="/AR/CommonAction" target="_blank" rel="noopener noreferrer">
                ما هو العمل المشترك؟
                </NavBtnLink11>
                <NavBtnLink11 to="/AR/Guidelines" target="_blank" rel="noopener noreferrer">
                    إقرأ دعوة تقديم الترشحات
                </NavBtnLink11>
                <NavBtnLink11AmaBerassmiLien href="https://meddialogue.eu/fellowship-post/common-action" target="_blank">
                    الأعمال المشتركة السابقة
                </NavBtnLink11AmaBerassmiLien>
        </ServicesWrapper1>
                    <div style={{width:"100%",marginTop:90}}>
                    <Timer>
                       <TimerWrapper1>
                            <TimerText>الموعد النهائي</TimerText>
                        </TimerWrapper1> 
                        <TimerWrapper>
                            <TimerText>{timerDays}<br></br><small>أيام</small></TimerText>
                            <TimerText>:</TimerText>
                            <TimerText>{timerHours}<br></br><small>ساعات</small></TimerText>
                            <TimerText>:</TimerText>
                            <TimerText>{timerMinutes}<br></br><small>دقيقة</small></TimerText>
                            <TimerText>:</TimerText>
                            <TimerText>{timerSeconds}<br></br><small>ثواني</small></TimerText>
                       </TimerWrapper> 
                    </Timer>
                    </div>=
        
        </>
    )
}

export default FormCivicAR
