import React from 'react'
import {TextField} from '@material-ui/core'
import {useField} from "formik";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';




const TextfieldWrapper1 = ({
  name,
  label,
  ...otherProps
}) => {
  const [field, mata] = useField(name);

  const theme = createMuiTheme({
  direction: 'rtl', 
  textAlign:"right"
});

  const configTextfield = {
    ...field,
    ...otherProps,
    fullWidth: true,
    variant: 'outlined'
  };

  if (mata && mata.touched && mata.error) {
    configTextfield.error = true;
    configTextfield.helperText = mata.error;
  }
  return (
    <ThemeProvider theme={theme}>
    <TextField placeholder={label} {...configTextfield} />
    </ThemeProvider>
  );
};
function textfieldWrapper1PropsAreEqual(prevTextfieldWrapper1, nextTextfieldWrapper1) {
  return !(prevTextfieldWrapper1.value === nextTextfieldWrapper1.value);
  
}
export default  React.memo(TextfieldWrapper1, textfieldWrapper1PropsAreEqual);