import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {HeroH112} from '../HeroSection/HeroSectionElements';
import Header from './headerAR';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: '30px 0 30px 0',
    
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 'bold',
    textAlign:'center',
    alignContent:'center',
    display:'block',
    alignItems:'center'
  },
  heading2: {
    fontSize: theme.typography.pxToRem(15),
    padding:'5px',
    direction:"rtl"
  },
  headingKbir: {
    fontSize: theme.typography.pxToRem(30),
    textAlign:'center',
    fontWeight:800,
    padding:'15px'
  },
  paper:{
    backgroundColor:'#338fbd',
    color:'#fff',
    textAlign:'center',
    alignContent:'center',
    alignItems:'center',
    "& .MuiAccordionSummary-content": { display: "block" }
  }
}));

const Faq = () => {
  const classes = useStyles();

  return (
    <>
        <Header/>
    <div className={classes.root}>
      <Typography className={classes.headingKbir}>
        ما هو العمل المشترك؟
      </Typography>
      <Typography className={classes.heading2}>
        يمكن تعريف العمل المشترك على أنه برنامج للعديد من الأعمال الموجهة لزيادة الوعي (حملات الإعلام / المناصرة ، إلخ) المبنية على الاحتياجات والسياق المحلي للمنطقة التي سيتم تنفيذها فيها وتطبيقها مع منظمة أو مجموعة متحدة من المنظمات.
      </Typography>
      <Typography className={classes.heading2}>
        يجب أن يكون للعمل المشترك تركيز مشترك بالاضافة إلى إطار عمل متفق عليه وكذلك تنسيق عملياتي مركزي ؛ كما يجب أن يفي بمعايير متماسكة ومضبوطة بشكل عام ويُنفذ من خلال الأنشطة اللامركزية ضمن إطار زمني محدد بشكل جيد. ستتم مناقشة كل هذه الميزات والاتفاق عليها في المخيم التدريبي.
      </Typography>
      <br></br>
      <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={classes.paper}
        >
          <Typography className={classes.heading} style={{direction:"rtl"}}>
          مدة العمل
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{display:"block"}} >
          <Typography style={{textAlign:"right",direction:"rtl"}}>
            سيتم تنفيذ العمل المشترك بطريقة إرشادية في شهر مارس 2022 ، ضمن إطار زمني سيتم تحديده في المخيم التدريبي.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className={classes.paper}
        >
          <Typography className={classes.heading}>
            الموارد
          </Typography>
        </AccordionSummary>
        <AccordionDetails  style={{display:"block"}}>
          <Typography style={{direction:"rtl",textAlign:"right"}}>
          لتنفيذ أنشطة العمل المشترك ، سيُعهد إلى منظمات أو شبكات القادة بمساهمة محدودة في التكاليف التشغيلية (1700 يورو لكل نشاط لامركزي). سيتم احتضان الأهداف، الشكل والجدول الزمني للعمل المشترك والاتفاق عليها خلال المخيم التدريبي.
          </Typography>
        </AccordionDetails>
      </Accordion>


      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className={classes.paper}
        >
          <Typography className={classes.heading}>
          الإبلاغ
          </Typography>
        </AccordionSummary>
        <AccordionDetails >
          <Typography style={{textAlign:"justify",direction:"rtl"}}>
          سيقوم فريق المساعدة الفنية بتوجيه ومراقبة تنفيذ كل عمل محلي. وسيُطلب كذلك من المستفيدين تقديم تقريرمالي نهائي مفصل بعد الانتهاء من العمل المُنفذ ، بما في ذلك تقديم المواد السمعية والبصرية التي توثق العمل. فضلا عن ذلك، سيتم تقديم مخطط لمتطلبات إعداد التقارير لصالح المستفيدين في "دليل إدارة العمل المشترك".
         </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className={classes.paper}
        >
          <Typography className={classes.heading} style={{direction:"rtl"}}>
          الرؤية
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{textAlign:"justify",direction:"rtl"}}>
           يجب على المستفيدين التأكد من وضوح دعم الاتحاد الأوروبي ، الممثل في هذه الحالة من خلال برنامج الدعم الفني حوار المتوسط للحقوق والمساواة. يرجى التحقق من متطلبات الاتصال والرؤية للأعمال الخارجية للاتحاد الأوروبي المحددة والمنشورة من قبل المفوضية الأوروبية   
          <a href="https://ec.europa.eu/international-partnerships/comm-visibility-requirements_en" target="_blank"> Communication and Visibility Requirements for EU External Actions specified and published by the European Commission</a> 
          </Typography>
        </AccordionDetails>
      </Accordion>
      <br></br>
      
    </div>
    </>
  );
}

export default Faq
