
import React, {useState} from 'react'
import Navbar from '../components/Navbar';
import Navbar2 from '../components/Navbar2';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import InfoSection2 from '../components/InfoSection2';
import Forms from '../components/HeroSection/bla';
import FormC from "../components/PersonalForm/Index"
import { Typography } from '@material-ui/core';
const FormCivic = () => {
    const [isOpen, setisOpen] = useState(false)

    const toggle = () =>{
        setisOpen(!isOpen)
    }
    
    return (
        <>
            <Navbar toggle={toggle}/>
            <Navbar2/>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <Forms/>
            <br></br>
            <br></br>
            <Typography style={{color:"rgba(249, 86, 79, 0.95)",fontWeight:600,textAlign:"center"}} variant="h4">
                Registrations are closed!
            </Typography>
            <br></br>
            <br></br>
            {/* <FormC/> */}

            <Footer/>
        </>
    )
}

export default FormCivic