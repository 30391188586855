
import React, {useState,useEffect} from 'react'
import Navbar from '../components/Navbar';
import Navbar2 from '../components/Navbar2';
import Sidebar from '../components/Sidebar';
import Faq from '../components/FAQ/faq';
import Footer from '../components/Footer';


const FrequentlyAskedQuestions = () => {
    const [isOpen, setisOpen] = useState(false)
    useEffect(() => {
        window.scrollTo(0, 200)
      }, [])
    const toggle = () =>{
        setisOpen(!isOpen)
    }
    return (
        <>
        <Navbar toggle={toggle}/>
        <Navbar2/>
        <Sidebar isOpen={isOpen} toggle={toggle} />
        <Faq/>
        <Footer/>
        </>
    )
}

export default FrequentlyAskedQuestions