import React, {useState, useEffect} from 'react';
import {FaBars} from 'react-icons/fa'
import {Nav, NavbarContainer, NavLogo, NavLogo1, Line, MobileIcon,Logo,SocialIcon,SocialIconLink, Textt,NavBtn,NavBtnLink , ServicesIcon } from './NavbarElemnts'
import {animateScroll as scroll} from 'react-scroll'
import { FaFacebook, FaInstagram, FaYoutube , FaTwitter } from 'react-icons/fa'

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  
    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return windowDimensions;
  }
const Navbar = ({toggle}) => {
    const [scrollNav, setScrollNav] = useState(false)
    const changeNav = () =>{
        if(window.scrollY >=100){
            setScrollNav(true)
        }
        else {
            setScrollNav(false)
        }
    }

    useEffect(()=> {
        window.addEventListener('scroll', changeNav)
        
    }, [])

    const toggleHome= () =>{
        scroll.scrollToTop();
    }
    const digStyle = {   
        background: '#fff',
        textDecoration: 'None',
        }
    const dighStyle = {   
            
            outline: 'None',
        }   
    const { height, width } = useWindowDimensions();
    return (
        
         
        <>
            <Nav>
                <NavbarContainer>
                        <Logo>
                        <NavLogo1 href='https://www.meddialogue.eu/'>
                            
                                <ServicesIcon src={require('../../images/Group1.png').default} alt={"Med Dialogue For Rights and Equality"} />
                           
                        </NavLogo1>
                            <Line></Line>
                        <NavLogo to='/' onClick={toggleHome}>
                            <ServicesIcon src={require('../../images/EuropeanUnion.png').default} alt={"Euripean Union"}/>
                        </NavLogo>
                        <Textt>Programme funded by the<br></br>European Union</Textt>
                        </Logo>
                        
                    </NavbarContainer>                    
                <NavbarContainer>
                        <NavBtn>
                                <NavBtnLink to="footer"
                                    smooth={true}
                                    duration={500}
                                    spy={true}
                                    exact='true'
                                    offset={160}
                                    activeClass="active">
                                        Subscribe to our Newsletter
                                </NavBtnLink>
                        </NavBtn>
                    
                </NavbarContainer>
                <NavbarContainer>
                    <SocialIcon>
                            <SocialIconLink href="https://www.facebook.com/MedDialogue/" target="_blank" aria-label="Facebook">
                                <FaFacebook/>
                            </SocialIconLink>
                        
                            <SocialIconLink href="https://www.instagram.com/med_dialogue/" target="_blank" aria-label="Instagram">
                                <FaInstagram/>
                            </SocialIconLink>

                            <SocialIconLink href="https://twitter.com/Med_Dialogue" target="_blank" aria-label="Youtube">
                                <FaTwitter/>
                            </SocialIconLink>

                            <SocialIconLink href="https://www.youtube.com/c/MedDialogue" target="_blank" aria-label="Youtube">
                                <FaYoutube/>
                            </SocialIconLink>
                            
                    </SocialIcon>

                                <MobileIcon onClick={toggle}>
                                    <FaBars/>
                                </MobileIcon>
                         
                </NavbarContainer>
            </Nav>
            
        </>
    )
}

export default Navbar;
