import React from 'react'
import { InfoContainer, InfoWrapper, FormTitle } from './PersonalElements'
import {Formik, Form} from "formik";
import * as Yup from "yup";
import { Grid,Typography,Button } from '@material-ui/core';
import TextField from './FormsUI/Textfield/Textfield';
import TextField2 from './FormsUI/Textfield2/Textfield2';
import TextField3 from './FormsUI/Textfield3/Textfield3';
import TextField4 from './FormsUI/Textfield4/Textfield4';
import Other from './FormsUI/Other/Other';
import Select from './FormsUI/Select/Select';
import ExperienceToo from '../../Data/FR/ExperienceField.json';
import CurrentActivity from '../../Data/FR/CurrentActivity.json';
import Experience from '../../Data/FR/Experience.json';
import Gender from '../../Data/FR/Gender.json';
import Title from '../../Data/FR/Title.json';
import YesNo from '../../Data/FR/YesNo.json';
import languages from '../../Data/FR/languages.json';
import DateTimePicker from './FormsUI/DataTimePicker/DataTimePicker';
import Radio from './FormsUI/Radio/Radio';
import Radio2 from './FormsUI/Radio2/Radio2';
import Radio3 from './FormsUI/Radio2/Radio3';
import Agree from './FormsUI/Agree/Agree';
import Buttonn from './FormsUI/Buttonn/Buttonn';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { getDatabase, ref as refReal, set } from "firebase/database";
import {AkwaLien} from "../Forms/Topicele";
import { v4 as uuidv4 } from 'uuid';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign:'center',
    border: '2px solid #01bf71',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));
const Hstyle = {
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily:"Segoe UI",
    fontWeight:'600',
    padding:'14px 8px',
  }
  const styleJdid = {
      alignItems: 'center',
      justifyContent: 'center',
      textAlign:"justify",
      fontFamily:"Segoe UI",
      padding:'14px 8px',
    }
const Hhstyle = {
    fontFamily:"Segoe UI",
    fontWeight:'600',
    padding:'14px 0px',
  }
const Bstyle = {
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight:'600',
    padding:'16px 8px',
  }
const Sstyle = {
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight:'600',
    fontSize:"14px",
    color:"#01bf71",
    padding:'16px 8px',
  }
  
const INITIAL_FORM_STATE ={ title:'',
    firstName:'',
    lastName:'',
    email:'',
    phone:'',
    aptNo:'',
    street:'',
    district:'',
    city:'',
    postalCode:'',
    country:'',
    gender:'',
    birthday:'',
    nationality:'',
    englishReading:'',
    englishSpeaking:'',
    englishListening:'',
    arabicReading:'',
    arabicSpeaking:'',
    arabicListening:'',
    frenchReading:'',
    frenchSpeaking:'',
    frenchListening:'',

    organisation:'',
    organisationLocation:'',
    currentWork:'',
    currentWork1:'',
    jobtitle:'',
    founder:'',
    awarness:'',
    experience:'',
    experienceTools:'',
    projectDescription:'',
    previousPartCritical:'',
    previousPartCritical1:'',
    previousPartAdvocacy:'',
    previousPartAdvocacy1:'',

    firstKey:'',
    secondKey:'',
    reason:'',

    CommonActionTitle:'',
    CommonActionPurpose:'',
    ProposedActivities:'',
    TargetGroup:'',
    timetable:'',
    specialCoaching:'',
    areasspecialCoaching:'',

    curriculumVitae:'',
    relfile:'',
    checkMe:''
};
const FORM_VALIDATION= Yup.object().shape({
    firstName: Yup.string()
        .required('Obligatoire'),
    lastName: Yup.string()
        .required('Obligatoire'),
    email: Yup.string()
        .email('Veuillez saisir un e-mail valide')
        .required('Obligatoire'),
    phone: Yup.number()
        .integer()
        .typeError("S'il vous plaît entrer un numéro de téléphone valide")
        .required('Obligatoire'),
    aptNo: Yup.string()
        .required('Obligatoire'),
    street: Yup.string()
        .required('Obligatoire'),
    district: Yup.string()
        .required('Obligatoire'),
    city: Yup.string()
        .required('Obligatoire'),
    postalCode: Yup.number()
        .integer()
        .typeError('Veuillez entrer un code postal valide')
        .required('Obligatoire'),
    country: Yup.string().required('Obligatoire'),
    birthday: Yup.date().required('Obligatoire'),
    nationality: Yup.string().required('Obligatoire'),
    englishReading: Yup.string().required('Obligatoire'),
    englishSpeaking: Yup.string().required('Obligatoire'),
    englishListening: Yup.string().required('Obligatoire'),
    arabicReading: Yup.string().required('Obligatoire'),
    arabicSpeaking: Yup.string().required('Obligatoire'),
    arabicListening: Yup.string().required('Obligatoire'),
    frenchReading: Yup.string().required('Obligatoire'),
    frenchSpeaking: Yup.string().required('Obligatoire'),
    frenchListening: Yup.string().required('Obligatoire'),
    
    organisation: Yup.string()
        .max(1500,'Veuillez ne pas dépasser 1500 caractères'),
    organisationLocation: Yup.string(),
    title: Yup
        .string()
        .required('Obligatoire'),
    gender: Yup
        .string()
        .required('Obligatoire'),
    currentWork : Yup
        .string(),
    currentWork1 : Yup
            .string(),
    jobtitle: Yup.string(),
    checkMe: Yup
        .boolean()
        .oneOf([true], "Vous devez accepter pour valider")
        .required('Obligatoire'),

    founder: Yup.string(),
    awarness: Yup.string(),
    experience: Yup.string().required('Obligatoire'),
    experienceTools:Yup.string().required('Obligatoire'),
    projectDescription:Yup.string().required('Obligatoire'),
    previousPartCritical:Yup.string().required('Obligatoire'),
    previousPartCritical1:Yup.string(),
    previousPartAdvocacy:Yup.string().required('Obligatoire'),
    previousPartAdvocacy1:Yup.string(),

    firstKey:Yup.string().required('Obligatoire')
                .max(1000,'Veuillez ne pas dépasser 1500 caractères'),
    secondKey:Yup.string().required('Obligatoire')
                .max(1000,'Veuillez ne pas dépasser 1500 caractères'),
    reason:Yup.string().required('Obligatoire')
                .max(1000,'Veuillez ne pas dépasser 1500 caractères'),
    
    CommonActionTitle: Yup.string().required('Obligatoire'),
    CommonActionPurpose: Yup.string().required('Obligatoire')
                            .max(750,'Veuillez ne pas dépasser 150 mots'),
    ProposedActivities: Yup.string().required('Obligatoire')
                            .max(1250,'Veuillez ne pas dépasser 250 mots'),
    TargetGroup:Yup.string().required('Obligatoire')
                            .max(750,'Veuillez ne pas dépasser 150 mots'),
    timetable:Yup.string().required('Obligatoire'),
    specialCoaching:Yup.string().required('Obligatoire'),
    areasspecialCoaching:Yup.string(),
    resume: Yup
        .mixed()
        .required("Document est requis")
    
})


const PersonalForm = () => {
    const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [filename, setFilename] = React.useState("CV");
  const [filename1, setFilename1] = React.useState("Document Pertinent");
  const handleOpen = () => {
    setOpen(true);
  };
  function refreshPage() {
    window.location.reload(false);
  }
  const handleClose = () => {
    setOpen(false);
    refreshPage();
  };
    return (
        <>
            <InfoContainer>
            
                <InfoWrapper>
                    
                    <Formik initialValues={{
                        ...INITIAL_FORM_STATE
                        }}
                        validationSchema={FORM_VALIDATION}
                        onSubmit={values =>{

                            console.log(values);

                            const storage = getStorage();
                            const db = getDatabase();

                            const file= values.resume;
                            const relevantFile= values.relevantFile;
                            const cvname= (values.firstName + ' ' +  values.resume.name);
                            let rvname='';
                            if (filename1!=='Document Pertinent'){
                                rvname= (values.firstName + ' ' +  values.relevantFile.name );
                            }
                            const storageRefCV = ref(storage, 'CV/'+cvname);
                            // const CV = storageRefCV.child();
                            if (rvname!==''){
                            const storageRefRF = ref(storage, 'RF/'+rvname);
                            uploadBytes(storageRefRF, relevantFile).then((snapshot) => {
                                console.log('Uploaded a Rf!');
                              });
                            }
                            uploadBytes(storageRefCV, file).then((snapshot) => {
                                console.log('Uploaded a RESUME!');
                              });
                            values.curriculumVitae=cvname;
                            values.relfile=rvname;
                            // let messageRef = firebase.database().ref('Applicants').orderByKey().limitToLast
                            set(refReal(db, 'users/'+values.firstName +" "+values.lastName + " "+uuidv4()), {
                                values
                              });
                            handleOpen();
                        }}
                    >
                    {({ 
                        setFieldValue,
                        setFieldTouched,
                        values,
                        errors,
                        touched,
                    }) => (
                        <Form>
                        <FormTitle>1. Informations personnelles </FormTitle>
                            <Grid container spacing={2}>
                            
                                <Grid item xs={3}>
                                    <Radio
                                        name="title"
                                        label="Title"
                                        legend="Titre"
                                        options={Title}
                                
                                    />
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <TextField
                                      name="firstName"
                                      label="Prénom"
                                      
                                    />
                                </Grid>

                                <Grid item md={4} sm={12}>
                                    <TextField
                                      name="lastName"
                                      label="Nom de famille"
                                    />
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <Radio
                                        name="gender"
                                        legend="Genre"
                                        label="Genre"
                                        options={Gender}
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                      name="email"
                                      label="Email"
                                    />
                                </Grid>

                                <Grid item md={4} sm={12}>
                                    <TextField
                                      name="phone"
                                      label="N° de téléphone"
                                    />
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <TextField
                                        name="nationality"
                                        label="Pays de la nationalité"
                                    />
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <DateTimePicker
                                      name="birthday"
                                      label="Date de naissance"
                                      
                                    />
                                </Grid>
                                
                                 <Grid item xs={12}>
                                    <Typography style={Hhstyle}>
                                        Adresse de résidence
                                    </Typography>
                                </Grid>

                                <Grid item md={4} sm={12}>
                                    <TextField
                                      name="aptNo"
                                      label="No app"
                                    />
                                </Grid>
                                
                                <Grid item md={4} sm={12}>
                                    <TextField
                                      name="street"
                                      label="Rue"
                                    />
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <TextField
                                      name="district"
                                      label="District"
                                    />
                                </Grid>

                                <Grid item md={4} sm={12}>
                                    <TextField
                                      name="city"
                                      label="Ville"
                                    />
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <TextField
                                      name="postalCode"
                                      label="Code Postal"
                                    />
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <TextField
                                        name="country"
                                        label="Pays"
                                    />
                                </Grid>
{/*********** Language Skills *************/}
                                <Grid item xs={12}>
                                    
                                <FormTitle><br></br><br></br>2. Compétences Linguistiques  </FormTitle>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography style={Hhstyle}>
                                        Anglais 
                                    </Typography>
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <Select
                                        name="englishReading"
                                        label="Lu"
                                        options={languages}
                                    />
                                </Grid>

                                <Grid item md={4} sm={12}>
                                    <Select
                                        name="englishSpeaking"
                                        label="Parlé"
                                        options={languages}
                                    />
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <Select
                                        name="englishListening"
                                        label="Compréhension"
                                        options={languages}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography style={Hhstyle}>
                                        Francais
                                    </Typography>
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <Select
                                        name="frenchReading"
                                        label="Lu"
                                        options={languages}
                                    />
                                </Grid>

                                <Grid item md={4} sm={12}>
                                    <Select
                                        name="frenchSpeaking"
                                        label="Parlé"
                                        options={languages}
                                    />
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <Select
                                        name="frenchListening"
                                        label="Compréhension"
                                        options={languages}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography style={Hhstyle}>
                                        Arabe
                                    </Typography>
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <Select
                                        name="arabicReading"
                                        label="Lu"
                                        options={languages}
                                    />
                                </Grid>

                                <Grid item md={4} sm={12}>
                                    <Select
                                        name="arabicSpeaking"
                                        label="Parlé"
                                        options={languages}
                                    />
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <Select
                                        name="arabicListening"
                                        label="Compréhension"
                                        options={languages}
                                    />
                                </Grid>
                                
{/************* Relevent experience **************/}
                                    <FormTitle><br></br><br></br>3. Parcours  </FormTitle>
                                        <Grid  style={Hstyle} xs={12}>
                                        Nom de votre Organisation 
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField2 
                                            name="organisation"
                                            />
                                        </Grid><br></br>
                                        <Grid  style={Hstyle} xs={12}>
                                            Localisation/pays de votre organisation 
                                        </Grid>
                                        <Grid item sm={12}>
                                            <TextField2 
                                            name="organisationLocation"
                                            />
                                        </Grid>
                                        <Grid  style={Hstyle} xs={12}>
                                        Votre poste/titre professionnel 
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField2
                                            name="jobtitle"
                                            />
                                        </Grid>
                                        <br></br>
                                        <Grid item xs={12}>
                                            <Radio 
                                            name="founder"
                                            legend="Êtes-vous un membre fondateur ou personne clé au sein  de votre organisation ?"
                                            options={YesNo}
                                            />
                                        </Grid>
                                <Grid item xs={12}>
                                    <Radio2 
                                        legend="Votre poste/service dans l’organisation est "
                                        name="currentWork"
                                        options={CurrentActivity}
                                    /><br></br>
                                </Grid>
                                {values.currentWork==="Autre" ? <>
                                        <Grid  style={Hstyle} xs={12}>
                                            Veuillez préciser :
                                        </Grid>
                                        <Grid item sm={8}>
                                            <TextField2 
                                            name="currentWork1"
                                            />
                                        </Grid></>
                                        :
                                        ""
                                    }
                                <Grid item xs={12}>
                                    <Radio2
                                        legend="Postulez-vous à cet appel en coordination avec le conseil d’administration ou la direction de votre organisation ?" 
                                        name="awarness"
                                        options={YesNo}
                                    />
                                </Grid>
                                <Grid xs={12} >
                                    <Grid item xs={12}>
                                        <Radio3
                                            legend="Depuis combien d’années êtes-vous impliqué dans les OSCs en tant qu’acteur de premier plan ou en tant que bénévole, d’une manière ou d’une autre ? Pour la définition de ce qu’est une OSC, consultez " 
                                            lien="les directives accompagnant le présent appel"
                                            winMachi="/FR/Guidelines"
                                            name="experience"
                                            options={Experience}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Radio2
                                            legend="Depuis combien de temps êtes-vous engagé dans le développement d’outils de pensée critique dans votre profession/activité ?" 
                                            name="experienceTools"
                                            options={ExperienceToo}
                                        />
                                    </Grid>
                                    <Grid  style={Hstyle} xs={12}>
                                    Veuillez décrire un projet visant à développer des notions et des outils de pensée critique dans lequel vous avez été impliqué en tant que leader ou participant
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField2 
                                        name="projectDescription"
                                        multiline={true}
                                        rows={4}
                                        />
                                    </Grid><br></br>
                                        <Grid item xs={12}>
                                            <Radio 
                                            name="previousPartCritical"
                                            legend="Avez-vous déjà participé à des sessions de formation sur les outils de pensée critique ?"
                                            options={YesNo}
                                            />
                                        </Grid>
                                        {values.previousPartCritical==="Oui"? 
                                            <>
                                            <Grid  style={Hstyle} xs={12}>
                                            Combien de fois et pour combien de temps ?
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField2 
                                                name="previousPartCritical1"
                                                multiline={true}
                                                rows={2}
                                                />
                                            </Grid>
                                            </>:""
                                        }<br></br>
                                        <Grid item xs={12}>
                                            <Radio 
                                            name="previousPartAdvocacy"
                                            legend="Avez-vous participé à des ateliers de plaidoyer/sensibilisation ou à la rédaction d’une campagne de plaidoyer basée sur l’utilisation de notions et d’outils de pensée critique ? "
                                            options={YesNo}
                                            />
                                        </Grid>
                                        {values.previousPartAdvocacy==="Oui"? 
                                            <>
                                            <Grid  style={Hstyle} xs={12}>
                                                Merci d’en faire une brève description
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField2 
                                                name="previousPartAdvocacy1"
                                                multiline={true}
                                                rows={2}
                                                />
                                            </Grid>
                                            </>:""
                                        }
                                <br></br><br></br>
                                </Grid>
                                

            {/************* 4.	Motivation  **************/}
            <FormTitle>4.	Motivation </FormTitle>
                                        <br></br>
                                <Grid xs={12} style={{marginLeft:10}}>
                                    <Typography variant="caption">Dans cette section, vous pouvez utiliser jusqu’à 1000 caractères par question, espaces compris</Typography>
                                </Grid>
                                <Grid  style={Hstyle} xs={12}>
                                    Décrivez deux objectifs personnels essentiels que vous souhaitez atteindre grâce à votre participation au Bootcamp Med Dialogue Leaders:
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField4 
                                      name="firstKey"
                                      multiline={true}
                                      rows={3}
                                      label="Premier Objectif"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField2 
                                      name="secondKey"
                                      multiline={true}
                                      rows={3}
                                      label="Deuxième Objectif"
                                    />
                                </Grid>
                                <Grid  style={Hstyle} xs={12}>
                                    Décrivez brièvement comment et pourquoi votre participation au Bootcamp Med Dialogue Leaders serait bénéfique pour vous et votre futur engagement envers votre communauté / organisation :
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField2 
                                      name="reason"
                                      multiline={true}
                                      rows={3}
                                      label=""
                                    />
                                </Grid>
    {/************* 5.	ICT tool Description  **************/}
                                <FormTitle><br></br><br></br>5. Votre proposition d’action commune  </FormTitle>
                                <Grid  style={styleJdid} xs={12}>
                                    <b>Pendant le Bootcamp</b>, les jeunes leaders oeuvreront à la conception d’une action commune, qui se déroulera simultanément dans les villes/régions d’origine des jeunes leaders, sous forme d’un ensemble d’activités locales répondant à des critères partagés et cohérents.
                                    <br></br>
                                    Le candidat est donc invité à soumettre une proposition pour une telle action commune, en présentant clairement les <b> objectifs envisagés de l’action commune, l’ensemble des activités nécessaires pour atteindre cet objectif et leur impact à l’échelle régionale.</b>
                                    <br></br>
                                    <br></br>
                                    Avant de commencer à remplir la partie relative à l’Action Commune ci-dessous, prière de lire attentivement la section <AkwaLien to="/FR/commonAction" target="_blank" rel="noopener noreferrer">Qu’est ce qu’une Action Commune ?</AkwaLien>
                                </Grid>
                                <Grid  style={Hstyle} xs={12}>
                                    <b>Titre de l’action commune :</b> <br></br>
                                    Formulez un titre qui reflète l’idée de projet principale de votre proposition d’action commune.
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField3 
                                      name="CommonActionTitle"
                                    />
                                </Grid>
                                <Grid  style={Hstyle} xs={12}>
                                    <b>Objectif de l’action commune et résultats attendus (150 mots max) :</b> <br></br>
                                    Formulez le but de l’action et les résultats attendus. Gardez à l’esprit que l’action doit être régionale et relever les principaux défis de la pensée critique en Méditerranée, ainsi qu’à améliorer la qualité de vie de la communauté du candidat. L’action commune proposée peut s’inspirer d’une évaluation des besoins et des contraintes de la communauté/du contexte du candidat  et doit viser à proposer des solutions pour relever les défis de la pensée critique dans d’autres zones de l’ensemble de la région. 
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField3 
                                      name="CommonActionPurpose"
                                      multiline={true}
                                      rows={2}
                                    />
                                </Grid>
                                <Grid  style={Hstyle} xs={12}>
                                    <b>Activités proposées (250 mots max) :</b> <br></br>
                                    <ul style={{marginLeft:30}}>
                                        <li>Décrivez l’ensemble des activités à entreprendre pour produire des résultats et précisez votre contribution possible à ces activités.</li>
                                        <li>Expliquez comment les activités proposées garantissent les résultats attendus.</li>
                                        <li>Expliquez si les activités proposées ont un potentiel de réplication (effets multiplicateurs) et si elles peuventt produire des effets durables après leur achèvement.</li>
                                    </ul>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField3 
                                      name="ProposedActivities"
                                      multiline={true}
                                      rows={3}
                                    />
                                </Grid>
                                <Grid  style={Hstyle} xs={12}>
                                    <b>Groupes cibles / partenaires (150 mots max) :</b> <br></br>
                                    Décrivez qui seraient les groupes cibles de l’action commune proposée, et les entités partenaires potentielles avec lesquelles l’action pourrait être menée
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField3 
                                      name="TargetGroup"
                                      multiline={true}
                                      rows={2}
                                    />
                                </Grid>
                                <Grid  style={Hstyle} xs={12}>
                                    <b>Calendrier indicatif :</b> <br></br>
                                    Proposer une durée indicative de l’action commune proposée (un jour, une semaine, etc.), en gardant à l’esprit qu’elle doit se dérouler dans un délai restreint, afin d’en amplifier l’impact critique.
                                    Il est recommandé de baser la durée estimée de l’action commune en prenant en considération tous les facteurs pertinents pouvant affecter le calendrier de mise en œuvre.
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField4 
                                      name="timetable"
                                      multiline={true}
                                      rows={4}
                                    />
                                </Grid>

{/************* 6.	Additional information    **************/}
                                <Grid xs={12}><br></br><br></br><br></br>
                                    <FormTitle>6.	Informations supplémentaires  </FormTitle>
                                </Grid>
                                <Grid item xs={12}>
                                    <Radio2
                                        name="specialCoaching"
                                        legend="Auriez-vous besoin d’un mentorat (encadrement) pour la mise en œuvre de votre action locale dans le cadre de l’action commune ?"
                                        options={YesNo}
                                    />
                                </Grid>
                                {
                                    (values.specialCoaching==="Oui") ?
                                        (<Grid item xs={12}>
                                        <Grid style={Hstyle} xs={12}>
                                            Si oui, expliquez dans quels domaines d’expertise et pourquoi vous pensez en avoir besoin.
                                        </Grid>
                                        <Grid xs={12}><Other
                                        name="areasspecialCoaching"
                                        
                                        /></Grid>
                                    </Grid>): ""
                                }
                                <Grid  style={Bstyle} xs={5}>
                                    {errors.resume ? 
                                    <Typography style={{color:"red"}}>Veuillez fournir votre CV récent*</Typography>
                                    :
                                    "Veuillez fournir votre CV récent*"}
                                </Grid>

                                <Grid item xs={2}>
                                    <Button
                                        variant="contained"
                                        component="label"
                                        fullwidth
                                        >
                                        Téléverser un fichier
                                        <input
                                            name="resume"
                                            type="file"
                                            hidden
                                            onChange={(event) => (setFieldValue('resume', event.target.files[0]),setFilename(event.target.files[0].name) )}
                                        />
                                        </Button>
                                </Grid>
                                
                                        {/* {(filename=='CV') ?(
                                            <Grid md={4} sm={12} style={redHstyle} >{filename}</Grid>
                                        ):
                                        ( */}
                                            <Grid md={4} sm={12} style={Hstyle} >{filename}</Grid>
                                        {/* )
                                        } */}
                                <br></br>
                                <Grid xs={12} style={{marginLeft:15,color:"red",fontSize:12}}>
                                {errors.resume}
                                </Grid>
                                <Grid  style={Bstyle} xs={5}>
                                    Veuillez fournir une lettre de recommandation
                                </Grid>

                                <Grid item xs={2}>
                                    <Button
                                        variant="contained"
                                        component="label"
                                        fullwidth
                                        >
                                        Téléverser un fichier
                                        <input
                                            name="relevantFile"
                                            type="file"
                                            hidden
                                            onChange={(event) => (setFieldValue('relevantFile', event.target.files[0]),setFilename1(event.target.files[0].name))}
                                            
                                        />
                                        {/* {if (!values.resume)} */}
                                        </Button>
                                </Grid>
                                <Grid md={4} sm={12} style={Hstyle}>{filename1}</Grid>
                                <br></br>
{/************* 8.	Applicant declaration    **************/}
                                <Grid xs={12}><br></br><br></br><br></br>
                                    <FormTitle>7.	Déclaration du candidat </FormTitle>
                                </Grid>
                                <Grid  style={Hstyle} xs={12}>
                                La/ Le candidat (e), dans le cadre du présent appel Med Dialogue Leaders, déclare que :
                                </Grid>
                                <Grid  style={Sstyle} xs={12}>
                                 - Elle/il répond aux critères d’éligibilité spécifiés dans l’appel.
                                </Grid>
                                <Grid  style={Sstyle} xs={12}>
                                 - Elle/il a la capacité de mener l’action commune développée pendant le Bootcamp dans sa communauté, ou avec son/ses organisation(s) associée(s), y compris les compétences et qualifications professionnelles pertinentes.
                                </Grid>
                                <Grid  style={Sstyle} xs={12}>
                                 - Elle/il serait directement responsable de la préparation, de la gestion et de la mise en œuvre de l’action commune dans sa communauté.
                                </Grid>
                                <Grid  style={Sstyle} xs={12}>
                                - Elle/il serait en mesure de démarrer la mise en œuvre de l’action commune dans le délai demandé.
                                </Grid>
                                <Grid  style={Sstyle} xs={12}>
                                 - Elle/il est disponible pour participer à l’activité Bootcamp en janvier 2022 et capable de voyager.
                                </Grid>
                                <Grid item xs={12}>
                                    <Agree
                                      name="checkMe"
                                      legend="En cliquant ici, vous signerez la déclaration du candidat"
                                      label="J'approuve"
                                    />
                                </Grid>
                                <Grid  style={Hstyle} xs={12}>
                                <i>Avant de cliquer sur le bouton «Soumettre», assurez-vous d’avoir vérifié toutes les informations fournies et joint toutes les pièces justificatives nécessaires. Le fait de ne pas soumettre les informations et la documentation requises dans le délai spécifié peut entraîner le rejet de la candidature.
                                  <br></br>Vous ne pourrez pas réviser le formulaire de candidature après sa soumission. Si vous êtes convaincu que votre candidature est complète et prête à être soumise, cliquez ici Bouton “Soumettre” ci-dessous.</i>
                                </Grid>
                                <Modal
                                        aria-labelledby="Merci pour votre soumission" className={classes.modal}
                                        open={open}
                                        onClose={handleClose}
                                        closeAfterTransition
                                        BackdropComponent={Backdrop}
                                        BackdropProps={{
                                        timeout: 500,
                                        }}
                                    >
                                        <Fade in={open}>
                                        <div className={classes.paper}>
                                            <h2 id="transition-modal-title">Merci pour votre soumission {values.firstName} {values.lastName}</h2>
                                            </div>
                                        </Fade>
                                    </Modal>

                                    

                                <Grid item xs={12}>
                                    <Buttonn >
                                        Soumettre
                                    </Buttonn>
                                </Grid>
                            </Grid>
                        </Form>)}
                    </Formik>
                </InfoWrapper>
            </InfoContainer>
        </>
    )
}

export default PersonalForm
