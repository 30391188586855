import file from './GuidelinesFR.pdf'
const GuidelineFR = () =>  {

    return (
    <object data={file} type="application/pdf" width="100%" height="800vh">
       <p>Votre navigateur Web n'a pas de plugin PDF.
           Au lieu de cela, vous pouvez <a href={file}>Téléchargez les lignes directrices.</a></p>
    </object>
    )
}

export default GuidelineFR
