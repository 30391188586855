
import file from './GuidelinesEN.pdf'
const GuidelineEN = () =>  {
  

    return (
    <object data={file} type="application/pdf" width="100%" height="800vh">
       <p>Your web browser doesn't have a PDF plugin.
          Instead you can <a href={file} download>Download the Guidelines.</a></p>
    </object>
    )
}

export default GuidelineEN
