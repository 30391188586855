import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Header from './header';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: '30px 0 30px 0',
    
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 'bold',
    textAlign:'center',
    alignContent:'center',
    display:'block',
    alignItems:'center'
  },
  heading2: {
    fontSize: theme.typography.pxToRem(15),
    padding:'5px'
  },
  headingKbir: {
    fontSize: theme.typography.pxToRem(30),
    textAlign:'center',
    fontWeight:800,
    padding:'15px'
  },
  paper:{
    backgroundColor:'#338fbd',
    color:'#fff',
    textAlign:'center',
    alignContent:'center',
    alignItems:'center',
    "& .MuiAccordionSummary-content": { display: "block" }
  }
}));

const Faq = () => {
  const classes = useStyles();

  return (
    <>
        <Header/>
    <div className={classes.root}>
      <Typography className={classes.headingKbir}>
        What is a Common Action?
      </Typography>
      <Typography className={classes.heading2}>
        A common action can be defined as a programme of several awareness-raising oriented actions (information/ advocacy campaigns, etc.) built upon the needs and local context of the area where they’d take place and implemented together with an organisation or a consortium of organisations. 
      </Typography>
      <Typography className={classes.heading2}>
        A common action must have a common focus, a commonly agreed framework and a central operational coordination; it meets commonly defined and coherent criteria and takes place through decentralized activities within a well-defined time frame, so as to embolden its critical mass. All these features will be discussed and agreed upon at the Bootcamp.
      </Typography>
      <br></br>
      <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={classes.paper}
        >
          <Typography className={classes.heading}>Action duration</Typography>
        </AccordionSummary>
        <AccordionDetails >
          <Typography style={{textAlign:"justify"}}>
          The common action shall be implemented indicatively in the month of March 2022, within a timeframe to be decided at the Bootcamp. 
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className={classes.paper}
        >
          <Typography className={classes.heading}>Resources</Typography>
        </AccordionSummary>
        <AccordionDetails >
          <Typography style={{textAlign:"justify"}}>
          To carry out the activities of the common action, Leaders’ organisations / networks will be entrusted with a limited contribution to operational costs (1,700 € for each decentralised activity). Objectives, format and timetable of the common action will be incubated and agreed upon during the Bootcamp. 
          </Typography>
        </AccordionDetails>
      </Accordion>


      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className={classes.paper}
        >
          <Typography className={classes.heading}>Reporting</Typography>
        </AccordionSummary>
        <AccordionDetails >
          <Typography style={{textAlign:"justify"}}>
          The TA team will guide and monitor the implementation of each local action. Beneficiaries will be required to submit a final financial and narrative report after the implemented action, including audio-visual material documenting the action. An outline of the reporting requirements will be provided to the beneficiaries in the “Guide to the Management of the Common action”. 
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className={classes.paper}
        >
          <Typography className={classes.heading}>Visibility </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{textAlign:"justify"}}>
          The beneficiaries must ensure the visibility of the European Union’s support, represented in this case through the Med Dialogue for Rights and Equality Technical Assistance Programme. Please check the <a href="https://ec.europa.eu/international-partnerships/comm-visibility-requirements_en" target="_blank">Communication and Visibility Requirements for EU External Actions specified and published by the European Commission</a>            </Typography>
        </AccordionDetails>
      </Accordion>
      <br></br>
      
    </div>
    </>
  );
}

export default Faq
