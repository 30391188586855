import styled from 'styled-components'
import {MdKeyboardArrowRight, MdArrowForward } from 'react-icons/md'
import {Link as LinkR} from 'react-router-dom'

export const TopLine = styled.p`
       margin-top:30px;
       font-size: 18px;
       line-height: 1.3;
       letter-spacing: 1.4px;
       margin-bottom: 16px;
       text-align:center;
`
export const TopLineAR = styled.p`
       margin-top:30px;
       font-size: 18px;
       line-height: 1.3;
       margin-bottom: 16px;
       text-align:center;
`
export const Heading = styled.h1`
       color:#393939;
       font-size: 32px;
       line-height: 1.1;
       font-weight: bold;
       text-align:center;
       margin-bottom: 16px;

       @media screen and (max-width: 480px){
           font-size: 24px;
       }
`
export const HeroContainer = styled.div`
       background: #c0c0c0c;
       padding: 25px 50px;
       height: 398px;
       position: relative;
       z-index: 1; 
       align-items:center;
        justify-content:center;
       
        @media screen and (max-width : 768px){
            height: 1000px;
            margin-top:200px;
        }
    
        @media screen and (max-width : 480px){
            height: 1200px;
        }
`

export const HeroContainer1 = styled.div`
       background: #c0c0c0c;
       padding: 25px 50px;
       height: 398px;
       position: relative;
       z-index: 1; 
       align-items:center;
        justify-content:center;
       
        @media screen and (max-width : 768px){
            height: 1000px;
            margin-top:200px;
        }
    
        @media screen and (max-width : 480px){
            height: 1200px;
        }
`
export  const HeroBg = styled.div`

    position: absolute;
    top: 0;
    right:0;
    bottom:0;
    left:0;
    width: 100%;
    height: 100%;
    overflow: hidden;
`

export const VideoBg = styled.img`
    
    @media screen and (min-width : 1000px){
        width:100%;
    }
    @media screen and (max-width : 768px){
        object-fit: cover;
    }
    @media screen and (max-width : 480px){
        object-fit: cover;
    }
`

export const HeroContent =styled.div`
    z-index : 6;
    max-width: 1200px;
    position: absolute;
    padding-left:25px;
    
        justify-content:center;
    align-items: center;
`

export const HeroH1 = styled.div`
    color: #fff;
    font-family: "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif;
    font-size:48px;
    font-weight:bold;
    border-raduis:50px;
    text-align: left;

    @media screen and (max-width : 768px){
        font-size: 40px;
    }

    @media screen and (max-width : 480px){
        font-size: 32px;
    }
`

export const HeroP = styled.p`
    margin-top: 24px;
    color: #000;
    font-size: 24px;
    text-align: justify;
    max-width: 600px;

    @media screen and (max-width : 1000px){
        font-size: 21px;
    }
    @media screen and (max-width : 768px){
        font-size: 18px;
    }
    @media screen and (max-width : 480px){
        font-size: 14px;
    }

`

export const HeroBtnWrapper =styled.div`
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
`



export const InfoWrapper = styled.div`
       display: grid;
       z-index: 1;
       height: 648px;
       width: 100%;
       max-wdith: 1100px;
       margin-right: auto;
       align-items:center;
       justify-content:center;
       margin-left: auto;
       margin:-18px 120px;
       padding: 25px;
       justify-content: center;
       @media screen and (max-width: 768px) {
             padding: 0 24px;
              height: 700px;
       }
`

export const InfoRow = styled.div`
       display: grid;
       grid-auto-columns: minmax(auto, 1fr);
       align-items: center;
       grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1 col3'`: `'col1 col2 col3'`)};

       @media screen and (max-width: 768px) {
           grid-template-areas: ${({imgStart}) => (imgStart ? `'col1 col1 col1' 'col2 col2 col2' 'col3 col3 col3'`: `'col1 col1 col1' 'col2 col2 col2' 'col3 col3 col3'`)};
       }
`

export const Column1 = styled.div`
       margin-bottom: 15px;
       padding: 0 15px;
       grid-area: col1;
`
export const Column2 = styled.div`
       margin-bottom: 15px;
       padding: 0 15px;
       grid-area: col2;
`
export const Column3 = styled.div`
       margin-bottom: 15px;
       padding: 0 15px;
       grid-area: col3;
`
export const TextWrapper = styled.div`
       max-width: 540px;
       padding-top: 0;
       padding-bottom: 60px;
`

export const Headingg = styled.h1`
       color:#fff;
       margin:10px;
       margin-bottom:0px;
       font-size: 40px;
       line-height: 1.4;
       font-weight: bold;
       text-align:center;
       @media screen and (max-width: 1000px){
        font-size: 32px;
        }
       @media screen and (max-width: 768px){
        font-size: 24px;
        }
       @media screen and (max-width: 480px){
           font-size: 24px;
       }
`
export const Headinggg = styled.h1`
       color:#fff;
       margin:10px;
       background-color:#7AAFC6;
       padding:5px;
       margin-bottom:0px;
       font-size: 40px;
       line-height: 1.4;
       font-weight: bold;
       text-align:center;
       @media screen and (max-width: 1000px){
        font-size: 32px;
        }
       @media screen and (max-width: 768px){
        font-size: 24px;
        }
       @media screen and (max-width: 480px){
           font-size: 24px;
       }
`
export const Subtitle =styled.p`
       font-family:"Segoe UI",sans-serif;
        text-align:center;
        margin-top:70px;
       font-size: 22px;
       font-weight:500;
       line-height: 24px;
       color: #fff;
       @media screen and (max-width : 1000px){
        margin-top:50px;
        font-size: 22px;
        }
        @media screen and (max-width : 768px){
            font-size: 20px;
        }
        @media screen and (max-width : 480px){
            font-size: 18px;
        }
`

export const BtnWrap = styled.div`
       display: flex;
       justify-content: flex-start;
`

export const ImgWrap = styled.div`
    max-width: 555px;
    height: 100%;
`

export const Img = styled.img`
       width: 100%;
       margin: 0 0 10px 0;
       padding-right: 0;
`
export const Img1 = styled.img`
       width: 3%;
       margin: 0 10px 0px 0;
       padding-right: 0;
`
export const ServicesPf = styled.b`
    color:#EE6A88;
    font-weight:bold;
`

export const List= styled.ul`
    display: block;
    list-style-type: none;
    padding-inline-start: 40px;
    text-decoration:none;
    color:#000;
`
export const LiList= styled.li`
    color:#000;
    margin-left:-40px;
    padding-top:10px;
    text-align:justify;
    text-decoration:none;
    font-size: 16px;
    &:hover{
        color: #FFAA04;
        transition: 0.6s ease-out;
    }
`

export const ServicesCard = styled.div`
    height: auto;
    flex-direction: column;
    justify-content: center ;
    border-radius: 10px;
    text-align: center;
    background:#EE6A88;
    padding: 15px 17px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    transition: all 0.2s ease-in-out;
    position:relative;
    z-index:1;
    &:hover{
        transform scale(1.02);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
    }
    @media screen and (max-width: 480px){
        padding:15px;
    }
`

export const ServicesIcon= styled.img`
    height: 250px;
    width:250px;
    margin-bottom: 10px;

`

export const ServicesH1 = styled.h1`
    font-size: 1.3rem;
    color:#fff;
    @media screen and (max-width: 1000px){
        font-size: 1.1rem;
        }
       @media screen and (max-width: 768px){
        font-size: 1rem;
        }
`


export const NavBtn= styled.nav`
    display: flex;
    align-items: center;
    justify-content:center;
    margin-top:20px;
    
    @media screen and (max-width: 786px){
    }
`

export const NavBtnLink = styled.a`
    font-family: font-family: 'alwyn-new-rounded-web',sans-serif;
    background: #0075B4;
    align-items: center;
    white-space: nowrap;
    padding: 14px 22px;
    color: #fff;
    font-weight:700;
    font-size: 22px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.8 ease-in-out;
    text-decoration:none;

&:hover{
    transition: all 0.8 ease-in-out;
    transition: background-color 0.2s linear;
    background:#fff;
    padding: 13px 22px;
    border: 2px solid #0075B4;
    color: #0075B4;
}
@media screen and (max-width: 786px){
    margin: 15px;
    font-size: 18px;
}
@media screen and (max-width: 480px){
    margin: 15px;
    font-size: 14px;
}
`

export const ServicesWrapper1 = styled.div`
    z-index:10;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr ;
    grid-gap: 40px;
    align-items:center;

    @media screen and (max-width: 1000px){
        grid-template-columns: 1fr 1fr ;
        padding: 0;
    }
    
    @media screen and (max-width: 768px){
        grid-template-columns: 1fr;
        padding: 0 ;
    }
`
export const NavBtnLink1 = styled(LinkR)`
    font-family: font-family: 'alwyn-new-rounded-web',sans-serif;
    background: #0075B4;
    align-items: center;
    border-radius: 0 0 25px 25px;
    margin:0 15px;
    text-align:center;
    white-space: nowrap;
    padding: 13px 22px;
    color: #fff;
    font-weight:500;
    height: 45px;
    width:210;
    font-size: 14px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.8 ease-in-out;
    text-decoration:none;
    @media screen and (max-width: 1000px){
        border-radius: 25px;
        width:auto;
    }
    @media screen and (max-width: 786px){
        margin: 15px 20px;
        font-size: 12px;
    }
&:hover{
    transition: all 0.8 ease-in-out;
    transition: background-color 0.2s linear;
    background:#fff;
    padding: 11px 20px;
    border: 2px solid #0075B4;
    color: #0075B4;
}
`

export const NavBtnLink11 = styled(LinkR)`
    font-family: font-family: 'alwyn-new-rounded-web',sans-serif;
    background: #0075B4;
    align-items: center;
    border-radius: 0 0 25px 25px;
    margin:0 15px;
    text-align:center;
    white-space: nowrap;
    padding: 13px 22px;
    color: #fff;
    font-weight:500;
    height: 45px;
    width:210;
    font-size: 14px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.8 ease-in-out;
    text-decoration:none;
    @media screen and (max-width: 1000px){
        border-radius: 25px;
        width:auto;
    }
    @media screen and (max-width: 786px){
        margin: 15px 20px;
        font-size: 12px;
    }
&:hover{
    transition: all 0.8 ease-in-out;
    transition: background-color 0.2s linear;
    background:#fff;
    padding: 11px 20px;
    border: 2px solid #0075B4;
    color: #0075B4;
}
`

export const AkwaLien = styled(LinkR)`
    font-weight:800;
    font-size: 16px;
    cursor: pointer;
    color:#0075B4;
    transition: all 0.8 ease-in-out;
    text-decoration: none;
    animation-name: blackWhite;  
    animation-iteration-count: infinite;  
    animation-duration: 2s; 
    animation-direction: alternate; 
    animation-timing-function: ease-in-out;
    @media screen and (max-width: 786px){
        font-size: 14px;
    }
// &:hover{
//     transition: all 0.8 ease-in-out;
//     transition: background-color 0.2s linear;
//     color: #005b8c;
// }
`
export const AkwaLienAR = styled(LinkR)`
    font-weight:800;
    font-size: 22px;
    cursor: pointer;
    color:#0075B4;
    transition: all 0.8 ease-in-out;
    text-decoration: none;
    animation-name: blackWhite;  
    animation-iteration-count: infinite;  
    animation-duration: 2s; 
    animation-direction: alternate; 
    animation-timing-function: ease-in-out;
    @media screen and (max-width: 786px){
        font-size: 14px;
    }
// &:hover{
//     transition: all 0.8 ease-in-out;
//     transition: background-color 0.2s linear;
//     color: #005b8c;
// }
`
export const NavBtnLink11AmaBerassmiLien = styled.a`
    font-family: font-family: 'alwyn-new-rounded-web',sans-serif;
    background: #0075B4;
    align-items: center;
    border-radius: 0 0 25px 25px;
    margin:0 15px;
    text-align:center;
    white-space: nowrap;
    padding: 13px 22px;
    color: #fff;
    font-weight:500;
    height: 45px;
    width:210;
    font-size: 14px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.8 ease-in-out;
    text-decoration:none;
    @media screen and (max-width: 1000px){
        border-radius: 25px;
        width:auto;
    }
    @media screen and (max-width: 786px){
        margin: 15px 20px;
        font-size: 12px;
    }
&:hover{
    transition: all 0.8 ease-in-out;
    transition: background-color 0.2s linear;
    background:#fff;
    padding: 11px 20px;
    border: 2px solid #0075B4;
    color: #0075B4;
}
`

export const NavBtnLink20 = styled(LinkR)`
    font-family: font-family: 'alwyn-new-rounded-web',sans-serif;
    background: #0075B4;
    align-items: center;
    border-radius: 0 0 25px 25px;
    margin:0 15px;
    text-align:center;
    white-space: nowrap;
    padding: 13px 22px;
    color: #fff;
    font-weight:500;
    height: 60px;
    width:210;
    font-size: 14px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.8 ease-in-out;
    text-decoration:none;
    @media screen and (max-width: 1000px){
        border-radius: 25px;
        width:auto;
    }
    @media screen and (max-width: 786px){
        margin: 15px 20px;
        font-size: 12px;
    }
&:hover{
    transition: all 0.8 ease-in-out;
    transition: background-color 0.2s linear;
    background:#fff;
    padding: 11px 20px;
    border: 2px solid #0075B4;
    color: #0075B4;
}
`
export const NavBtnLink13 = styled(LinkR)`
    font-family: font-family: 'alwyn-new-rounded-web',sans-serif;
    background: #66D0BA;
    align-items: center;
    border-radius: 0 0 25px 25px;
    margin:0 15px;
    text-align:center;
    white-space: nowrap;
    padding: 13px 22px;
    color: #fff;
    font-weight:500;
    height: 45px;
    width:210;
    font-size: 14px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.8 ease-in-out;
    text-decoration:none;
    @media screen and (max-width: 1000px){
        width:auto;
        border-radius: 25px;
    }
    @media screen and (max-width: 786px){
        margin: 15px 20px;
        font-size: 12px;
    }
&:hover{
    transition: all 0.8 ease-in-out;
    transition: background-color 0.2s linear;
    background:#fff;
    padding: 11px 20px;
    border: 2px solid #66D0BA;
    color: #66D0BA;
}
`

export const ServicesWrapper111 = styled.div`
    z-index:10;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
    max-width:1920px;
    @media screen and (max-width: 1000px){
        grid-template-columns: 1fr 1fr ;
        padding: 0;
    }
    
    @media screen and (max-width: 768px){
        grid-template-columns: 1fr;
        padding: 0 ;
    }
`
export const NavBtnLink2 = styled(LinkR)`
    font-family: font-family: 'alwyn-new-rounded-web',sans-serif;
    grid-area: 1 / col6-start;
    background: #0075B4;
    align-items: center;
    border-radius: 0 0 25px 25px;
    margin:0 15px;
    float:right;
    text-align:center;
    white-space: nowrap;
    padding: 13px 22px;
    color: #fff;
    font-weight:500;
    height: 45px;
    width:210;
    font-size: 14px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.8 ease-in-out;
    text-decoration:none;
    @media screen and (max-width: 1000px){
        width:auto;
    }
    @media screen and (max-width: 786px){
        margin: 15px 20px;
        font-size: 12px;
    }
&:hover{
    transition: all 0.8 ease-in-out;
    transition: background-color 0.2s linear;
    background:#fff;
    padding: 11px 20px;
    border: 2px solid #0075B4;
    color: #0075B4;
}
`


export const Timer = styled.div`
    color:#fff;
    background-color: rgb(249,86,79,0.95);
    align-items: center;
    font-weight:bold; 
    vertical-align: middle;
    display:flex;
    margin-top:20px;
    flex-direction:row;
    border-radius: 25px;
    max-width: 500px;
`
export const TimerAR = styled.div`
    color:#fff;
    background-color: rgb(249,86,79,0.95);
    align-items: center;
    font-weight:bold; 
    vertical-align: middle;
    display:flex;
    margin-top:40px;
    flex-direction:row;
    border-radius: 0 25px 25px 0;
    width: 450px;
`
export const TimerWrapper1 = styled.div`
    border-radius: 25px;
    justify-content:center;
    vertical-align: middle;
    margin-top:3px;
    min-width:160px;
    padding:0.5em;
    text-align:center;
    @media screen and (max-width: 480px) {
        padding: 1em 0.3em;
        }
`
export const TimerWrapper = styled.div`
    border-radius: 25px;
    justify-content:center;
    vertical-align: middle;
    display: grid;
    grid-template-columns:repeat(7, 1fr);
    margin-top:3px;
    padding:0.5em;
    text-align:center;
    @media screen and (max-width: 480px) {
        padding: 1em 0.3em;
        }
`
export const TimerText = styled.p`
    font-size: 1em;
`

export const TextwFok = styled.b`
    font-size: 1em;
`
export const TextwMatfokch = styled(LinkR)`
    text-decoration:none;
    color:#0075B4;
    &:hover{
        color: #FFAA04;
        transition: 0.4s ease-out;
        cursor: pointer;
    }
`
export const TextwMatfokch1 = styled.b`
    font-size:1.5em;
    color:rgb(249,86,79,0.95);
`