import React, {Component} from 'react'
import TextField1 from '../Textfield1/Textfield1';
import {useField} from "formik";

class OtherWrapper extends Component {
shouldComponentUpdate(nextProps) { 
    // Rendering the component only if  
    // passed props value is changed 
  
    if (nextProps.value !== this.props.value) { 
      return true; 
    } else { 
      return false; 
    } 
    }
    
  render() {
    const { label, name, ...attributes } = this.props;
    // const err = getIn([errors, name]);
    // const touch = getIn([touched, name]);
    return (
      <TextField1 
      name={name}
      label={label}
      />
      
    );
  }
}
export default  OtherWrapper;