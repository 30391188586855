
import file from './GuidelinesAR.pdf'
const GuidelineAR = () =>  {

    return (
    <object data={file} type="application/pdf" width="100%" height="800vh">
          <p style={{direction:"rtl"}}>لا يحتوي متصفح الويب الخاص بك على مكون إضافي بتنسيق PDF.
             بدلا من ذلك يمكنك ذلك <a href={file} download> تنزيل إرشادات التسجيل</a></p>
    </object>
    )
}

export default GuidelineAR
