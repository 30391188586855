import React from 'react'
import { InfoContainer, InfoWrapper,FormTitle } from './PersonalElements'
import {Formik, Form} from "formik";
import * as Yup from "yup";
import { Grid,Typography,Button } from '@material-ui/core';
import TextField from './FormsUIAR/Textfield/Textfield';
import TextField2 from './FormsUIAR/Textfield2/Textfield2';
import TextField3 from './FormsUIAR/Textfield3/Textfield3';
import TextField4 from './FormsUIAR/Textfield4/Textfield4';
import Other from './FormsUIAR/Other/Other';
import Select from './FormsUIAR/Select/Select';
import ExperienceToo from '../../Data/AR/ExperienceField.json';
import CurrentActivity from '../../Data/AR/CurrentActivity.json';
import Experience from '../../Data/AR/Experience.json';
import Gender from '../../Data/AR/Gender.json';
import Title from '../../Data/AR/Title.json';
import YesNo from '../../Data/AR/YesNo.json';
import languages from '../../Data/AR/languages.json';
import DateTimePicker from './FormsUIAR/DataTimePicker/DataTimePicker';
import Radio from './FormsUIAR/Radio/Radio';
import Radio2 from './FormsUIAR/Radio2/Radio2';
import Radio3 from './FormsUI/Radio2/Radio3';
import Agree from './FormsUIAR/Agree/Agree';
import Buttonn from './FormsUIAR/Buttonn/Buttonn';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { getDatabase, ref as refReal, set } from "firebase/database";
import {AkwaLienAR} from "../Forms/Topicele";
import { v4 as uuidv4 } from 'uuid';


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign:'center',
    border: '2px solid #01bf71',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));
  const styleJdid = {
      alignItems: 'right',
      justifyContent: 'right',
      textAlign:"justify",
      fontFamily:"Segoe UI",
      padding:'14px 8px',
    }
const Hstyle = {
    alignItems: 'right',
    justifyContent: 'right',
    fontFamily:"Segoe UI",
    fontWeight:'600',
    padding:'14px 8px',
  }
const Hhstyle = {
    fontFamily:"Segoe UI",
    fontWeight:'600',
    padding:'14px 0px',
  }
const Bstyle = {
    alignItems: 'right',
    justifyContent: 'right',
    fontWeight:'600',
    padding:'16px 8px',
  }
const Sstyle = {
    alignItems: 'right',
    justifyContent: 'right',
    fontWeight:'600',
    fontSize:"14px",
    color:"#01bf71",
    padding:'16px 8px',
  }
  
const INITIAL_FORM_STATE ={
    title:'',
    firstName:'',
    lastName:'',
    email:'',
    phone:'',
    aptNo:'',
    street:'',
    district:'',
    city:'',
    postalCode:'',
    country:'',
    gender:'',
    birthday:'',
    nationality:'',
    englishReading:'',
    englishSpeaking:'',
    englishListening:'',
    arabicReading:'',
    arabicSpeaking:'',
    arabicListening:'',
    frenchReading:'',
    frenchSpeaking:'',
    frenchListening:'',

    organisation:'',
    organisationLocation:'',
    currentWork:'',
    currentWork1:'',
    jobtitle:'',
    founder:'',
    awarness:'',
    experience:'',
    experienceTools:'',
    projectDescription:'',
    previousPartCritical:'',
    previousPartCritical1:'',
    previousPartAdvocacy:'',
    previousPartAdvocacy1:'',

    firstKey:'',
    secondKey:'',
    reason:'',
    
    CommonActionTitle:'',
    CommonActionPurpose:'',
    ProposedActivities:'',
    TargetGroup:'',
    timetable:'',
    specialCoaching:'',
    areasspecialCoaching:'',

    curriculumVitae:'',
    relfile:'',
    checkMe:''

};
const FORM_VALIDATION= Yup.object().shape({
    firstName: Yup.string()
        .required('إجباري'),
    lastName: Yup.string()
        .required('إجباري'),
    email: Yup.string()
        .email('يرجى إدخال البريد الإلكتروني صالح')
        .required('إجباري'),
    phone: Yup.number()
        .integer()
        .typeError("يرجى إدخال البريد الإلكتروني الصحيح")
        .required('إجباري'),
    aptNo: Yup.string()
        .required('إجباري'),
    street: Yup.string()
        .required('إجباري'),
    district: Yup.string()
        .required('إجباري'),
    city: Yup.string()
        .required('إجباري'),
    postalCode: Yup.number()
        .integer()
        .typeError('الرجاء إدخال الرمز البريدي صالح')
        .required('إجباري'),
    country: Yup.string().required('إجباري'),
    country1: Yup.string(),
    birthday: Yup.date().required('إجباري'),
    nationality: Yup.string().required('إجباري'),
    nationality1: Yup.string(),
    englishReading: Yup.string().required('إجباري'),
    englishSpeaking: Yup.string().required('إجباري'),
    englishListening: Yup.string().required('إجباري'),
    arabicReading: Yup.string().required('إجباري'),
    arabicSpeaking: Yup.string().required('إجباري'),
    arabicListening: Yup.string().required('إجباري'),
    frenchReading: Yup.string().required('إجباري'),
    frenchSpeaking: Yup.string().required('إجباري'),
    frenchListening: Yup.string().required('إجباري'),
    
    organisation: Yup.string()
        .max(1500,'من فضلك لا تتجاوز 1500 حرف'),
        organisationLocation: Yup.string(),
        title: Yup
            .string()
            .required('إجباري'),
        gender: Yup
            .string()
            .required('إجباري'),
        currentWork : Yup
            .string(),
        currentWork1 : Yup
                .string(),
        jobtitle: Yup.string(),
        checkMe: Yup
            .boolean()
            .oneOf([true], "You need to Accept in Order to Validate")
            .required('إجباري'),
        founder: Yup.string(),
        awarness: Yup.string(),
        experience: Yup.string().required('إجباري'),
        experienceTools:Yup.string().required('إجباري'),
        projectDescription:Yup.string().required('إجباري'),
        previousPartCritical:Yup.string().required('إجباري'),
        previousPartCritical1:Yup.string(),
        previousPartAdvocacy:Yup.string().required('إجباري'),
        previousPartAdvocacy1:Yup.string(),
    
        firstKey:Yup.string().required('إجباري')
                    .max(1000,'الرجاء عدم تجاوز 1000 حرف'),
        secondKey:Yup.string().required('إجباري')
                    .max(1000,'الرجاء عدم تجاوز 1000 حرف'),
        reason:Yup.string().required('إجباري')
                    .max(1000,'الرجاء عدم تجاوز 1000 حرف'),
        
        CommonActionTitle: Yup.string().required('إجباري'),
        CommonActionPurpose: Yup.string().required('إجباري')
                                .max(750,'الرجاء عدم تجاوز 100 كلمة'),
        ProposedActivities: Yup.string().required('إجباري')
                                .max(1250,'الرجاء عدم تجاوز 250 كلمة'),
        TargetGroup:Yup.string().required('إجباري')
                                .max(750,'الرجاء عدم تجاوز 100 كلمة'),
        timetable:Yup.string().required('إجباري'),
        specialCoaching:Yup.string().required('إجباري'),
        areasspecialCoaching:Yup.string(),
    
        resume: Yup
            .mixed()
            .required("السيرة الذاتية إجبارية")
    
})


const PersonalForm = () => {
    const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [filename, setFilename] = React.useState("سيرة الذاتية");
  const [filename1, setFilename1] = React.useState("وثيقة أخرى");
  const handleOpen = () => {
    setOpen(true);
  };
  function refreshPage() {
    window.location.reload(false);
  }
  const handleClose = () => {
    setOpen(false);
    refreshPage();
  };
    return (
        <>
            <InfoContainer style={{direction:"rtl"}}>
            
                <InfoWrapper>
                    
                <Formik initialValues={{
                        ...INITIAL_FORM_STATE
                        }}
                        validationSchema={FORM_VALIDATION}
                        onSubmit={values =>{

                            console.log(values);

                            const storage = getStorage();
                            const db = getDatabase();

                            const file= values.resume;
                            const relevantFile= values.relevantFile;
                            const cvname= (values.firstName + ' ' +  values.resume.name);
                            let rvname='';
                            if (filename1!=='وثيقة أخرى'){
                                rvname= (values.firstName + ' ' +  values.relevantFile.name );
                            }
                            const storageRefCV = ref(storage, 'CV/'+cvname);
                            // const CV = storageRefCV.child();
                            if (rvname!==''){
                            const storageRefRF = ref(storage, 'RF/'+rvname);
                            uploadBytes(storageRefRF, relevantFile).then((snapshot) => {
                                console.log('Uploaded a Rf!');
                              });
                            }
                            uploadBytes(storageRefCV, file).then((snapshot) => {
                                console.log('Uploaded a RESUME!');
                              });
                            values.curriculumVitae=cvname;
                            values.relfile=rvname;
                            // let messageRef = firebase.database().ref('Applicants').orderByKey().limitToLast
                            set(refReal(db, 'users/'+values.firstName +" "+values.lastName + " "+uuidv4()), {
                                values
                              });
                            handleOpen();
                        }}
                    >
                    {({ 
                        setFieldValue,
                        setFieldTouched,
                        values,
                        errors,
                        touched,
                    }) => (
                        <Form>
                        <FormTitle>1.  المعلومات الشخصية </FormTitle>
                            <Grid container spacing={2}>
                            
                                <Grid item md={4} sm={12}>
                                    <Radio
                                        name="title"
                                        label="Title"
                                        legend="اللقب"
                                        options={Title}
                                
                                    />
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <TextField
                                      name="firstName"
                                      label="الاسم الاول"
                                      
                                    />
                                </Grid>

                                <Grid item md={4} sm={12}>
                                    <TextField
                                      name="lastName"
                                      label="اللقب"
                                    />
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <Radio
                                        name="gender"
                                        legend="الجنس"
                                        label="الجنس"
                                        options={Gender}
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                      name="email"
                                      label="البريد الالكتروني"
                                    />
                                </Grid>

                                <Grid item md={4} sm={12}>
                                    <TextField
                                      name="phone"
                                      label="رقم الهاتف"
                                    />
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <TextField
                                        name="nationality"
                                        label="الجنسية"
                                    />
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <DateTimePicker
                                      name="birthday"
                                      label="تاريخ الميلاد"
                                      
                                    />
                                </Grid>
                                
                                
                                 <Grid item xs={12}>
                                    <Typography style={Hhstyle}>
                                        عنوان الاقامة
                                    </Typography>
                                </Grid>

                                <Grid item md={4} sm={12}>
                                    <TextField
                                      name="aptNo"
                                      label="رقم الشقة"
                                    />
                                </Grid>
                                
                                <Grid item md={4} sm={12}>
                                    <TextField
                                      name="street"
                                      label="الشارع"
                                    />
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <TextField
                                      name="district"
                                      label="المقاطعة"
                                    />
                                </Grid>

                                <Grid item md={4} sm={12}>
                                    <TextField
                                      name="city"
                                      label="المدينة / البلدة"
                                    />
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <TextField
                                      name="postalCode"
                                      label="الرمز البريدى"
                                    />
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <TextField
                                        name="country"
                                        label="الدولة"
                                    />
                                </Grid>
{/*********** Language Skills *************/}
                                <Grid item xs={12}>
                                    
                                <FormTitle><br></br><br></br>2. مهارات اللغة  </FormTitle>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography style={Hhstyle}>
                                        الإنجليزية 
                                    </Typography>
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <Select
                                        name="englishReading"
                                        label="قراءة"
                                        options={languages}
                                    />
                                </Grid>

                                <Grid item md={4} sm={12}>
                                    <Select
                                        name="englishSpeaking"
                                        label="تحدث"
                                        options={languages}
                                    />
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <Select
                                        name="englishListening"
                                        label="الاستماع والفهم"
                                        options={languages}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography style={Hhstyle}>
                                        الفرنسية
                                    </Typography>
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <Select
                                        name="frenchReading"
                                        label="قراءة"
                                        options={languages}
                                    />
                                </Grid>

                                <Grid item md={4} sm={12}>
                                    <Select
                                        name="frenchSpeaking"
                                        label="تحدث"
                                        options={languages}
                                    />
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <Select
                                        name="frenchListening"
                                        label="الاستماع والفهم"
                                        options={languages}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography style={Hhstyle}>
                                        العربية
                                    </Typography>
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <Select
                                        name="arabicReading"
                                        label="قراءة"
                                        options={languages}
                                    />
                                </Grid>

                                <Grid item md={4} sm={12}>
                                    <Select
                                        name="arabicSpeaking"
                                        label="تحدث"
                                        options={languages}
                                    />
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <Select
                                        name="arabicListening"
                                        label="الاستماع والفهم"
                                        options={languages}
                                    />
                                </Grid>
                                
{/************* Relevent experience **************/}
                                <Grid container xs={12}>
                                    <FormTitle><br></br><br></br>3. الخبرة  </FormTitle>
                                        <Grid  style={Hstyle} xs={12}>
                                        اسم مؤسستك (إن وجد)
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField2 
                                            name="organisation"
                                            />
                                        </Grid><br></br>
                                        <Grid  style={Hstyle} xs={12}>
                                            موقع مؤسستك / البلد (إن وجد)    
                                        </Grid>
                                        <Grid item sm={12}>
                                            <TextField2 
                                            name="organisationLocation"
                                            />
                                        </Grid>
                                        <Grid  style={Hstyle} xs={12}>
                                        منصبك / المسمى الوظيفي (إن وجد) 
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField2
                                            name="jobtitle"
                                            />
                                        </Grid>
                                        <br></br>
                                        <Grid item xs={12}>
                                            <Radio 
                                            name="founder"
                                            legend="هل أنت مؤسس أو شخص رئيسي في المنظمة"
                                            options={YesNo}
                                            />
                                        </Grid>
                                <Grid item xs={12}>
                                    <Radio2 
                                        legend="منصبي / خدمتي في المنظمة هي:"
                                        name="currentWork"
                                        options={CurrentActivity}
                                    /><br></br>
                                </Grid>
                                {values.currentWork==="غير ذلك" ? <>
                                        <Grid  style={Hstyle} xs={12}>
                                        يرجى التحديد:
                                        </Grid>
                                        <Grid item sm={8}>
                                            <TextField2 
                                            name="currentWork1"
                                            />
                                        </Grid></>
                                        :
                                        ""
                                    }
                                <Grid item xs={12}>
                                    <Radio2
                                        legend="هل تتقدم بطلب لهذه الدعوة بالتنسيق مع مجلس الإدارة أوعن طريق إدارة منظمتك؟ " 
                                        name="awarness"
                                        options={YesNo}
                                    />
                                </Grid>
                                <Grid xs={12} >
                                    <Grid item xs={12}>
                                        <Radio3
                                            legend="كم سنة شاركت في منظمات المجتمع المدني التي تقوم بدور قيادي في مجتمعك أو تتطوع بأي شكل من الأشكال ؟ لتعريف منظمات المجتمع المدني CSO ، تحقق من  " 
                                            lienAR="تعليمات الدعوة"
                                            name="experience"
                                            winMachi="/AR/Guidelines"
                                            options={Experience}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Radio2
                                            legend="منذ متى وأنت تشارك في تطوير أدوات التفكير النقدي في عملك / نشاطك؟" 
                                            name="experienceTools"
                                            options={ExperienceToo}
                                        />
                                    </Grid>
                                    <Grid  style={Hstyle} xs={12}>
                                    يرجى وصف مشروع يهدف إلى تطوير مفاهيم وأدوات التفكير النقدي التي ساهمت فيها كقائد أو مشارك
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField2 
                                        name="projectDescription"
                                        multiline={true}
                                        rows={4}
                                        />
                                    </Grid><br></br>
                                        <Grid item xs={12}>
                                            <Radio 
                                            name="previousPartCritical"
                                            legend="هل سبق لك المشاركة في دورات تدريبية على أدوات التفكير النقدي؟"
                                            options={YesNo}
                                            />
                                        </Grid>
                                        {values.previousPartCritical==="نعم"? 
                                            <>
                                            <Grid  style={Hstyle} xs={12}>
                                            فكم كان عددها وإلى غاية متى؟
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField2 
                                                name="previousPartCritical1"
                                                multiline={true}
                                                rows={2}
                                                />
                                            </Grid>
                                            </>:""
                                        }<br></br>
                                        <Grid item xs={12}>
                                            <Radio 
                                            name="previousPartAdvocacy"
                                            legend="هل شاركت في ورش عمل للمناصرة / زيادة الوعي أو في صياغة حملة مناصرة قائمة على استخدام مفاهيم وأدوات التفكير النقدي؟ "
                                            options={YesNo}
                                            />
                                        </Grid>
                                        {values.previousPartAdvocacy==="نعم"? 
                                            <>
                                            <Grid  style={Hstyle} xs={12}>
                                            يرجى وصف ذلك
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField2 
                                                name="previousPartAdvocacy1"
                                                multiline={true}
                                                rows={2}
                                                />
                                            </Grid>
                                            </>:""
                                        }
                                <br></br><br></br>
                                </Grid>
                                </Grid>
                                

            {/************* 4.	Motivation  **************/}
                                <Grid container xs={12}>
            <FormTitle>4.	الحافز   </FormTitle>
                                        <br></br>
                                <Grid xs={12} style={{marginLeft:10}}>
                                    <Typography variant="caption"> في هذا القسم ، يمكنك استخدام ما يصل إلى 1000 حرف لكل سؤال ، مع تضمين المسافات</Typography>
                                </Grid>
                                <Grid  style={Hstyle} xs={12}>
                                أذكر هدفين رئيسين  تريد تحقيقهما  على المستوى الشخصي من خلال مشاركتك في برنامج قادة حوار المتوسط Med Dialogue Leaders
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField4 
                                      name="firstKey"
                                      multiline={true}
                                      rows={3}
                                      label="الهدف الأول"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField2 
                                      name="secondKey"
                                      multiline={true}
                                      rows={3}
                                      label="الهدف الثاني"
                                    />
                                </Grid>
                                <Grid  style={Hstyle} xs={12}>
                                صف بإيجاز كيف ولماذا ستكون مشاركتك في المخيم التدريبي لقادة حوار المتوسط مفيدة لك ولالتزامك المستقبلي تجاه مجتمعك / منظمتك:
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField2 
                                      name="reason"
                                      multiline={true}
                                      rows={3}
                                      label=""
                                    />
                                </Grid>
    {/************* 5.	ICT tool Description  **************/}
                                <FormTitle><br></br><br></br>5. اقتراح العمل المشترك الخاص بك   </FormTitle>
                                <Grid  style={styleJdid} xs={12}>
                                    <b>خلال المخيم التدريبي </b>، سيعمل القادة الشباب على تصور عمل مشترك ، والذي سيجري بالتزامن في موطن القادة الشباب كمجموعة من الأنشطة المحلية التي تلبي المعايير المشتركة والمترابطة.
                                    <br></br>
                                    لذلك ، فإن مقدم الطلب مدعو بموجب هذا لتقديم اقتراح لمثل هذا العمل المشترك ، مع عرض واضح <b> للهدف المُتصور له وكذلك عرض مجموعة الأنشطة اللازمة لتحقيق هذا الهدف بالاضافة تأثيرها على المستوى الإقليمي. </b>
                                    <br></br>
                                    <br></br>
                                    قبل البدء في ملء إستمارة العمل المشترك أدناه، الرجاء القراءة بعناية قسم <AkwaLienAR to="/AR/commonAction" target="_blank" rel="noopener noreferrer">"ما هو العمل المشترك؟"</AkwaLienAR>.
                                </Grid>
                                <Grid  style={Hstyle} xs={12}>
                                    <b>عنوان العمل المشترك  :</b> <br></br>
                                    قم بصياغة عنوان يعكس فكرة المشروع الرئيسة لمقترحك الخاص بالعمل المشترك
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField3 
                                      name="CommonActionTitle"
                                    />
                                </Grid>
                                <Grid  style={Hstyle} xs={12}>
                                    <b>هدف العمل المشترك والنتائج المتوقعة (150 كلمة كحد أقصى) :</b> <br></br>
                                    صياغة الهدف من العمل والنتائج المتوقعة. خذ بعين الاعتبار أن العمل يجب أن يكون إقليميًا حيث يهدف إلى معالجة تحديات التفكير النقدي الرئيسة في منطقة البحر الأبيض المتوسط ، بالإضافة إلى تحسين نوعية الحياة في مجتمعك. يمكن أن يستلهم العمل المشترك المُقترح من خلال تقييم احتياجات وقيود مجتمعك، ويهدف بالتالي إلى اقتراح حلول لمواجهة تحديات التفكير النقدي في أماكن أخرى عبر كامل المنطقة.
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField3 
                                      name="CommonActionPurpose"
                                      multiline={true}
                                      rows={2}
                                    />
                                </Grid>
                                <Grid  style={Hstyle} xs={12}>
                                    <b>الأنشطة المقترحة (250 كلمة كحد أقصى) :</b> <br></br>
                                    <ul style={{marginLeft:30}}>
                                        <li>
                                        	صف مجموعة الأنشطة التي يتعين القيام بها لتحقيق النتائج وأيضا حدد مساهمتك المحتملة في ذلك.
                                        </li>
                                        <li>
                                        	اشرح كيف تضمن الأنشطة المقترحة النتائج المتوقعة.
                                        </li>
                                        <li>
                                        	اشرح ما إذا كانت الأنشطة المقترحة لها إمكانية تكرار (تأثيرات مضاعفة) ، وما إذا كان بالامكان أن تنتج تأثيرات مستدامة بعد الانتهاء منها.
                                        </li>
                                    </ul>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField3 
                                      name="ProposedActivities"
                                      multiline={true}
                                      rows={3}
                                    />
                                </Grid>
                                <Grid  style={Hstyle} xs={12}>
                                    <b>الفئات المستهدفة / الشركاء (150 كلمة كحد أقصى) :</b> <br></br>
                                    صِف من ستُعتبر المجموعات المستهدفة للعمل المشترك المقترح ، وكذلك الكيانات الشريكة المحتملة التي يمكن تنفيذه معها
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField3 
                                      name="TargetGroup"
                                      multiline={true}
                                      rows={2}
                                    />
                                </Grid>
                                <Grid  style={Hstyle} xs={12}>
                                    <b>الجدول الزمني الإرشادي :</b> <br></br>
                                      	اقترح مدة للعمل المشترك المقترح (يوم واحد ، أسبوع واحد ، إلخ) ، مع الأخذ بعين الاعتبار أنه يجب أن يتم في إطار زمني محدد ، وذلك لتعزيز الأثر.
                                    يوصى بتحديد المدة المقدرة للعمل المشترك من خلال مراعاة جميع العوامل ذات الصلة التي قد تؤثر على الجدول الزمني للتنفيذ.
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField4 
                                      name="timetable"
                                      multiline={true}
                                      rows={4}
                                    />
                                </Grid>
                            </Grid>

{/************* 6.	Additional information    **************/}
                                <Grid xs={12}><br></br><br></br><br></br>
                                    <FormTitle>6.	معلومات إضافية    </FormTitle>
                                </Grid>
                                <Grid item xs={12}>
                                    <Radio2
                                        name="specialCoaching"
                                        legend="هل تحتاج إلى إرشاد لتنفيذ عملك المحلي في إطار العمل المشترك؟"
                                        options={YesNo}
                                    />
                                </Grid>
                                {
                                    (values.specialCoaching==="نعم") ?
                                        (<Grid item xs={12}>
                                        <Grid style={Hstyle} xs={12}>
                                        إذا كان الأمر كذلك ، فاشرح في أي مجال من مجالات الخبرة ولماذا تعتقد أنك بحاجة إليه.
                                        </Grid>
                                        <Grid xs={12}><Other
                                        name="areasspecialCoaching"
                                        
                                        /></Grid>
                                    </Grid>): ""
                                }
                                <Grid  style={Bstyle} xs={5}>
                                    {errors.resume ? 
                                    <Typography style={{color:"red"}}>يرجى تقديم سيرتك الذاتية الأخيرة*</Typography>
                                    :
                                    "يرجى تقديم سيرتك الذاتية الأخيرة*"}
                                </Grid>

                                <Grid item xs={2}>
                                    <Button
                                        variant="contained"
                                        component="label"
                                        fullwidth
                                        >
                                        تحميل ملف
                                        <input
                                            name="resume"
                                            type="file"
                                            hidden
                                            onChange={(event) => (setFieldValue('resume', event.target.files[0]),setFilename(event.target.files[0].name) )}
                                        />
                                        </Button>
                                </Grid>
                                
                                        {/* {(filename=='CV') ?(
                                            <Grid md={4} sm={12} style={redHstyle} >{filename}</Grid>
                                        ):
                                        ( */}
                                            <Grid md={4} sm={12} style={Hstyle} >{filename}</Grid>
                                        {/* )
                                        } */}
                                <br></br>
                                <Grid xs={12} style={{marginLeft:15,color:"red",fontSize:12}}>
                                {errors.resume}
                                </Grid>
                                <Grid  style={Bstyle} xs={5}>
                                يرجى تقديم خطاب توصية  
                                </Grid>

                                <Grid item xs={2}>
                                    <Button
                                        variant="contained"
                                        component="label"
                                        fullwidth
                                        >
                                        تحميل ملف
                                        <input
                                            name="relevantFile"
                                            type="file"
                                            hidden
                                            onChange={(event) => (setFieldValue('relevantFile', event.target.files[0]),setFilename1(event.target.files[0].name))}
                                            
                                        />
                                        {/* {if (!values.resume)} */}
                                        </Button>
                                </Grid>
                                <Grid md={4} sm={12} style={Hstyle}>{filename1}</Grid>
                                <br></br>
{/************* 8.	Applicant declaration    **************/}
                                <Grid xs={12}><br></br><br></br><br></br>
                                    <FormTitle>7.	تصريح مقدم الطلب</FormTitle>
                                </Grid>
                                <Grid  style={Hstyle} xs={12}>
                                يُقر مقدم الطلب ، في سياق الدعوة الحالية لقادة حوار المتوسط ، بما يلي:
                                </Grid>
                                <Grid  style={Sstyle} xs={12}>
                                 - 	يستوفي/ تستوفي معايير الأهلية المحددة في الدعوة؛
                                </Grid>
                                <Grid  style={Sstyle} xs={12}>
                                 - لديه/ لديها القدرة على تنفيذ العمل المشترك الذي تم تطويره أثناء المخيم التدريبي في مجتمعه / مجتمعها ، أو مع المنظمة (المنظمات) المرتبطة به / بها ، بما في ذلك الكفاءات والمؤهلات المهنية ذات الصلة ؛
                                </Grid>
                                <Grid  style={Sstyle} xs={12}>
                                 - سيكون/ ستكون مسؤولا/ مسؤولة بشكل مباشر عن إعداد، إدارة وتنفيذ العمل المشترك في مجتمعه / مجتمعها؛
                                </Grid>
                                <Grid  style={Sstyle} xs={12}>
                                 - سيكون/ ستكون في وضع يسمح له/ لها ببدء تنفيذ الإجراء المشترك ضمن الإطار الزمني المطلوب
                                </Grid>
                                <Grid  style={Sstyle} xs={12}>
                                    - سيكون/ ستكون جاهزا(ة) للمشاركة في نشاط المخيم التدريبي في جانفي/ يناير 2022 وقادرا/ قادرة على السفر.
                                </Grid>
                                <Grid item xs={12}>
                                    <Agree
                                      name="checkMe"
                                      legend="بالضغط هنا ستوقع على تصريح تقديم الطلب"
                                      label="أنا موافق"
                                    />
                                </Grid>
                                <Grid  style={Hstyle} xs={12}>
                                 <i>قبل النقر على زر "تقديم" ، يرجى التأكد من وضعك علامة على جميع المعلومات المقدمة وإرفاق جميع المستندات الداعمة اللازمة. قد يؤدي عدم تقديم المعلومات والوثائق المطلوبة خلال الموعد النهائي المحدد إلى رفض الطلب.
                                  <br></br>لن تتمكن من مراجعة نموذج الطلب بعد التقديم. إذا كنت واثقًا تمامًا من أن طلبك مكتمل وجاهز للتقديم ،انقر هنا زر "تقديم" أدناه.</i>
                                </Grid>
                                <Modal
                                        aria-labelledby="شكرا لك على تقديمك" className={classes.modal}
                                        open={open}
                                        onClose={handleClose}
                                        closeAfterTransition
                                        BackdropComponent={Backdrop}
                                        BackdropProps={{
                                        timeout: 500,
                                        }}
                                    >
                                        <Fade in={open}>
                                        <div className={classes.paper}>
                                            <h2 id="transition-modal-title">شكرا لك على تقديمك {values.firstName} {values.lastName}</h2>
                                            </div>
                                        </Fade>
                                    </Modal>

                                    

                                <Grid item xs={12}>
                                    <Buttonn >
                                    تقديم
                                    </Buttonn>
                                </Grid>
                            </Grid>
                        </Form>)}
                    </Formik>
                </InfoWrapper>
            </InfoContainer>
        </>
    )
}

export default PersonalForm
