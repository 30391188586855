import React, {useRef,useEffect,useState} from 'react';
import {HeroContainer1, HeroBg,Timer,ImgBg, TimerText,TimerWrapper1, TimerWrapper,StickyBarWrraper, StickyBar,StickyBarText, HeroContent, InfoRow } from './HeroSectionElements'
import {ServicesWrapper1,NavBtnLink11,NavBtnLink11AmaBerassmiLien} from '../Forms/Topicele'
const HeroSection = () => {
const [hover, setHover] = useState(false)

    const [timerDays, setTimerDays] = useState('00');
    const [timerHours, setTimerHours] = useState('00');
    const [timerMinutes, setTimerMinutes] = useState('00');
    const [timerSeconds, setTimerSeconds] = useState('00');
    const [timerDays1, setTimerDays1] = useState('00');
    const [timerHours1, setTimerHours1] = useState('00');
    const [timerMinutes1, setTimerMinutes1] = useState('00');
    const [timerSeconds1, setTimerSeconds1] = useState('00');
    let interval= useRef();

    const startTimer =() => {
        const countdownDate= new Date('Octobre 22, 2021 15:00:00');
        const countdownDate1= new Date('May 16, 2021 22:00:00');

        interval=setInterval(() => {
            const now = new Date().getTime();
            const distance = countdownDate- now;
            const days= Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours= Math.floor((distance % (1000 * 60 * 60 * 24) / (1000 * 60 * 60)));
            const minutes= Math.floor((distance % (1000 * 60 * 60)) / (1000*  60));
            const seconds= Math.floor((distance % (1000 * 60)) / 1000);
            if (distance < 0){
                // stop our Timer
                clearInterval(interval.current);
            } else{
                setTimerDays(days);
                setTimerHours(hours);
                setTimerMinutes(minutes);
                setTimerSeconds(seconds);
                
            };
            
            const distance1 = countdownDate1- now;
            const days1= Math.floor(distance1 / (1000 * 60 * 60 * 24));
            const hours1= Math.floor((distance1 % (1000 * 60 * 60 * 24) / (1000 * 60 * 60)));
            const minutes1= Math.floor((distance1 % (1000 * 60 * 60)) / (1000*  60));
            const seconds1= Math.floor((distance1 % (1000 * 60)) / 1000);
            if (distance1 < 0){
                // stop our Timer
                clearInterval(interval.current);
            } else{
                setTimerDays1(days1);
                setTimerHours1(hours1);
                setTimerMinutes1(minutes1);
                setTimerSeconds1(seconds1);
            }
        }, 1000);
    };
    useEffect(() => {
        startTimer();
        return () => {
            clearInterval(interval.current);
        };
    });
const onHover = () =>{
    setHover(!hover)
}

    return (
        <>
        <HeroContainer1 id="Hero">
            <HeroBg>
                <ImgBg src={require('../../images/banner-22-EN.png').default} alt={"Boot Camp Med Dialgoue for rights and equality funded by European Union"} />
            </HeroBg>
            <StickyBarWrraper>
            <StickyBar>
                <StickyBarText to='/Form'>EN</StickyBarText>
                <StickyBarText to='/FR/Form'>FR</StickyBarText>
                <StickyBarText to='/AR/Form'>عر</StickyBarText>
            </StickyBar>
            </StickyBarWrraper>
            <HeroContent>
                <InfoRow>
                    {/* <Column11>
                    <HeroH11 to='/'><b>#HackToAct</b> Hackathon</HeroH11>
                        <HeroH2>
                        <b>Civil society activists registration is Closed!</b>
                        </HeroH2>
                        
                    </Column11> */}
                    
                    
                    
                </InfoRow>
                
            </HeroContent>
            
        </HeroContainer1>
        <ServicesWrapper1>
                <NavBtnLink11 to="/CommonAction" target="_blank" rel="noopener noreferrer">
                    What is a Common Action?
                </NavBtnLink11>
                <NavBtnLink11 to="/Guidelines" target="_blank" rel="noopener noreferrer">
                    Read the Call for Applications
                </NavBtnLink11>
                <NavBtnLink11AmaBerassmiLien href="https://meddialogue.eu/fellowship-post/common-action" target="_blank">
                    Our previous Common Actions
                </NavBtnLink11AmaBerassmiLien>
        </ServicesWrapper1>
                    <div style={{width:"100%",marginTop:90}}>
                    <Timer>
                       <TimerWrapper1>
                            <TimerText>Deadline</TimerText>
                        </TimerWrapper1> 
                        <TimerWrapper>
                            <TimerText>{timerDays}<br></br><small>Days</small></TimerText>
                            <TimerText>:</TimerText>
                            <TimerText>{timerHours}<br></br><small>Hours</small></TimerText>
                            <TimerText>:</TimerText>
                            <TimerText>{timerMinutes}<br></br><small>Minutes</small></TimerText>
                            <TimerText>:</TimerText>
                            <TimerText>{timerSeconds}<br></br><small>Seconds</small></TimerText>
                       </TimerWrapper> 
                    </Timer>
                    </div>
        </>
    )
}

export default HeroSection
